import axios from "services/setupAxios";
import { PENDING_REQUEST_LIST, RESPONSE_PENDING_REQUEST } from "utils/request";

export const getPendingRequestAPIAffiliate_Admin = ({ params }) => {
  return axios.get(PENDING_REQUEST_LIST, { params });
};

export const getPendingRequestAPI = () => {
  return axios.post(PENDING_REQUEST_LIST);
};

export const responsePendingRequest = ({ data }) => {
  return axios.post(RESPONSE_PENDING_REQUEST, data);
};
