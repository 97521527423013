import { editAff_pkTypes } from "utils/constants";

const initialState = {
  listAff_pk: [],
};

export const aff_pkReducer = (state = initialState, action) => {
  switch (action.type) {
    case editAff_pkTypes.ADD_AFF_PK:
      const addAff = state.listAff_pk;

      return {
        ...state,
        listAff_pk: [...addAff, action.payload],
      };

    case editAff_pkTypes.REMOVE_AFF_PK:
      const removeAff = state.listAff_pk.filter((item) => {
        return item !== action.payload;
      });
      return {
        ...state,
        listAff_pk: removeAff,
      };

    case editAff_pkTypes.CLEAR_AFF_PK:
      return {
        ...state,
        listAff_pk: [],
      };

    default:
      return state;
  }
};
