import React from "react";
import Form from "./components/Form";
import Layout from "components/layout";
import { CREATE_USER } from "utils/constants";

export default function Register() {
  return (
      <Layout title={CREATE_USER}  style={{border: 'solid green'}}>
        <Form />
      </Layout>
  );
}
