import { previousPageTypes } from "utils/constants";

const initialState = {
  URL: "",
};

export const previousPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case previousPageTypes.ADD_URL_PAGE:
      return {
        ...state,
        URL: action.payload,
      };

    case previousPageTypes.DELETE_URL_PAGE:
      return {
        ...state,
        URL: "",
      };

    default:
      return state;
  }
};
