import React, { useState } from "react";
import { DisableButton, GreenButton } from "components/Button";
import { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import authActions from "redux/actions/auth";
import { schemaSignIn } from "utils/schema";
import { notifySuccess, notifyError } from "utils/helper";
import { LOGIN_SUCCESS_MESSAGE } from "utils/constants";
import "styles/engage-tool.css";
import logo from '../../../../assets/images/logo.webp'

export default function Form({ userData, onAuthCodeChange, onResend }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [authErr, setAuthErr] = useState(false);

  const {
    //register,
    //handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaSignIn),
  });

  const onResendClicked = async () => {
    setLoadingResend(true);
    const result = await onResend({
      username: userData.username,
      password: userData.password,
    });
    setLoadingResend(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // If MFA is enabled, sign-in should be confirmed with the confirmation code
    try {
      const response = await Auth.sendCustomChallengeAnswer(
        userData.user, // Return object from Auth.signIn()
        userData.code // Confirmation code
      );
      try {
        //const response = await Auth.signIn(userName, password);
        const setUserSession = await Auth.currentSession();

        const role = setUserSession.getIdToken().payload.role;
        const aff_pk = setUserSession.getIdToken().payload.aff_pk;
        const state = setUserSession.getIdToken().payload?.state;
        const affiliate_assoc =
          setUserSession.getIdToken().payload?.affiliate_assoc;

        dispatch(
          await authActions.loginSuccess({
            isLoggedIn: true,
            email: response?.attributes?.email,
            name: response?.attributes?.name,
            role: role,
            aff_pk: aff_pk,
            state: state,
            affiliate_assoc: affiliate_assoc,
          })
        );

        notifySuccess(LOGIN_SUCCESS_MESSAGE);
        setLoading(false);
      } catch (err) {
        notifyError(err.message);
        setAuthErr(true);
        setLoading(false);
        console.log(err);
      } finally {
        setLoading(false);
      }
    } catch (err) {
      notifyError(err.message);
      setAuthErr(true);
      setLoading(false);
      if (err.code === "UserNotConfirmedException") {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === "PasswordResetRequiredException") {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.code === "NotAuthorizedException") {
        // The error happens when the incorrect password is provided
      } else if (err.code === "UserNotFoundException") {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      } else {
        console.log(err);
      }
    }
  };

  return (
    <div className="login-form-container">
      <img src={logo} alt="AFSCME logo" className="logo"/>
      <p className="login-form-header">AUTHENTICATE YOUR ACCOUNT</p>
      <small className="helper-text">Please confirm your account by entering the authorization code sent to
      your email.</small>
      {/* Authorization Code form */}
      <form onSubmit={onSubmit} className="mt-3">
        <div>
          <input
            className="form-control"
            name="auth_code"
            type="text"
            onChange={(e) => {
              setAuthErr(false);
              onAuthCodeChange(e);
            }}
            placeholder="Authorization Code"
          />
          {authErr && (
            <small className="authentication-code-error">
              Invalid authentication code.
            </small>
          )}
        </div>
        <div className="mt-3 mb-3 d-flex justify-content-center">
          {loading ? (
            <DisableButton className="disabled-btn" text="SUBMIT" />
          ) : (
            <GreenButton className="login-form-btn" text="SUBMIT" />
          )}
        </div>
      </form>
      <div id="authInfo">
        <small className="helper-text">It may take a minute to receive the authorization code.If
        you haven't received the authorization code,{" "}
        <button className="text-white" onClick={loadingResend ? () => {} : onResendClicked}>
          <u>{loadingResend ? "loading..." : "resend the code"}</u>
        </button>
        .</small>
      </div>
        
    </div>
  );
}
