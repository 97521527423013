import { bestPracticesTypes } from "utils/constants";

const addArticle = (payload) => ({
  type: bestPracticesTypes.ADD_ARTICLE,
  payload,
});

const deleteArticle = (payload) => ({
  type: bestPracticesTypes.DELETE_ARTICLE,
  payload,
});

const bestPracticesActions = {
  addArticle,
  deleteArticle,
};

export default bestPracticesActions;
