/* This example requires Tailwind CSS v2.0+ */
import { useState} from "react";
import { notifySuccess, notifyError } from "utils/helper";
import { requestAddChestLink } from "services/toolChest";
import { tool_chest_request } from "utils/constants";
import './AddChestItem.css'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

export default function ToolChestItem({handleModalClose, addItem}) {
  const [loading, setLoading] = useState(false);
  
  const schema = yup.object({
    url: yup.string().test(
      'valid-url', 'Enter a valid URL', value => {
        if(!value) return false;

        if(value.startsWith('www.')){
          value = 'http://' + value;
        }

        try{
          new URL(value);
          return true
        } catch {
          return false;
        }
      }
    ).required("Please enter a link."),
    title: yup.string().required("Please enter title"),
    description: yup.string().required("Please enter a short description").min(10, "Please enter description of at least 10 characters").max(250, "Please enter a description of less than 250 characters"),
  }).required();

  const { register, handleSubmit, formState: {errors}} = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      url: '',
      title: '',
      description: ''
    },
  });

  const addNewItem = async(data) => {
    try{
      setLoading(true);
      await addItem(data);
      handleModalClose();
    } catch(error){
      console.error(`Error adding chest item: ${error}`)
    } finally{
      setLoading(false);
    }
  }


  
  return (
    <form className='add-tool-chest-item-container' onSubmit={handleSubmit(addNewItem)}>
      <label htmlFor="title">Title:</label>
      <input
        {...register("title")}
        id="title"
        type="text"
        placeholder=" Add title"
        />
        {errors.title && <p className="warning">{errors.title.message}</p>}
      <label htmlFor="url">Link:</label>
      <input
        {...register("url")}
        id="url"
        type="text"
        placeholder=" Add link..."
        
        />
        {errors.link && <p className="warning">{errors.link.message}</p>}
      <label htmlFor="description">Description:</label>
      <textarea
        {...register("description")}
        id="description"
        type="text"
        placeholder=" Add description"
        />
        {errors.description && <p className="warning">{errors.description.message}</p>}
      <div className="add-tool-chest-item-btn-group">
        <button
          type="submit"
          id="save-tool-chest-item"
          disabled={loading}>
          {loading ? "Loading..." : "Save"}
        </button>
        <button
          id="cancel-tool-chest-item"
          type="button"
          onClick={handleModalClose}>
          Cancel
        </button>
      </div>
    </form>
  );
}
