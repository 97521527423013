import React, { createContext, useContext, useState, useEffect, useCallback} from "react";
import { CREATE_TOOL_CHEST_LINK, TOOL_CHEST_LINK_LIST } from "utils/request";
import { Auth } from "aws-amplify";


/**
 * Type interfaces:
 */
interface ItemToDelete {
    uuid: string,
    role: string,
    affPk_timestamp: string
}

interface ItemToAdd{
    url: string,
    title: string,
    description: string;
}

interface MetaDataInfoObj{
    site_name?: string,
    description?: string,
    image?: {
        url: string
    }
    title?: string,
    type?: string,
}

interface ChestListItemType {
    aff_pk: string,
    created_at: string,
    role: string,
    input_url?: string,
    short_name?: string,
    metadata_info?: MetaDataInfoObj,
    uuid: string,
    owner: string,
    description: string,
    url: string,
    affPk_timestamp: string,
    title: string   
}

interface ChestItemsContextType {
    items: ChestListItemType[],
    loading: boolean,
    addItem: (itemToAdd: ItemToAdd) => Promise<void>,
    deleteItem: (itemToDelete: ItemToDelete) => Promise<void>
}


/**
 * Setting up context
 */
const ChestItemsContext = createContext<ChestItemsContextType | undefined>(undefined);

const getToken = async() => {
    try{
        const session = await Auth.currentSession();
        return session.getIdToken().getJwtToken().trim();

        
    } catch(err){
        throw new Error(`Failed to fetch token due to: ${err}`)
    }
}

export const useChestItemContext = () => {
    const context = useContext(ChestItemsContext);

    if(!context){
        throw new Error("useChestItemContext must be used within a ChestItemsProvider")
    };

    return context;
}

const ChestItemsProvider = ({ children }): JSX.Element =>  {
    const [items, setItems] = useState<ChestListItemType[]>([]);
    const [hasFetched, setHasFetched] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchItems = useCallback(async() => {
        const token = await getToken();

        try{
            setLoading(true);
            const response = await fetch(TOOL_CHEST_LINK_LIST, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": token, 
                }
            })
            
            const data = await response.json();
            if(data){
                setLoading(false);
                setItems(data);
                setHasFetched(true);
            }

        } catch (error){
            throw new Error(`Could not retrieve chest list: ${error}`)
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if(!hasFetched){
            fetchItems();
        }
    }, [fetchItems, hasFetched, items]);


    const addItem = useCallback(async(itemToAdd: ItemToAdd) => {
        console.log(`Items being added: ${JSON.stringify(itemToAdd)}`)

        try{
            const token = await getToken();
            const response = await fetch(CREATE_TOOL_CHEST_LINK, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    create: [{...itemToAdd}]
                })
            });

            const resp = await response.json();
            const data = resp.response.create[0].data
            setItems(prevItems => [...prevItems, data]);
            
        } catch(error){
            throw new Error(`Could not add to chest list: ${error}`)
        }
    }, []);

    const deleteItem = useCallback(async(itemToDelete: ItemToDelete) => {
        const {uuid, role, affPk_timestamp} = itemToDelete

        console.log(`ItemToDelete: ${JSON.stringify(itemToDelete)}`)
        try{
            const token = await getToken();
            await fetch(CREATE_TOOL_CHEST_LINK, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    delete: [{
                        role,
                        affPk_timestamp
                    }]
                })
            });

            setItems(prevItems => prevItems.filter(item => item.uuid !== uuid))
        } catch (error){
            throw new Error(`Could not delete chest list item: ${error}`)
        }
    }, []);


    return (
        <ChestItemsContext.Provider value={{items, addItem, deleteItem, loading}}>
            {children}
        </ChestItemsContext.Provider>
    )
}

export default ChestItemsProvider; 


