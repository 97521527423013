import Layout from "components/layout";
import React from "react";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
import 'styles/engage-tool.css';

export default function NEO() {
  return (
    <Layout title="NEO">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb>
              <BreadcrumbItem href='./best-practice'>Best Practices</BreadcrumbItem>
              <BreadcrumbItem active>NEO</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </div>
          <div className="row">
            <div className="col-md-12">
              <div className="previewBox" ><a  href="/categories/Article" target="_blank">
                <h5>Welcome potential new members to their workplace!</h5>
                <p>Use this best practice to create a welcome e-mail to welcome your potential new members to the workplace and introduce them to AFSCME!</p>
                </a></div>
            </div>
          </div>
      </div>
    </Layout>
  );
}