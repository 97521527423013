import axios from "services/setupAxios";
import { CREATE_TOOL_CHEST_LINK, TOOL_CHEST_LINK_LIST } from "utils/request";

export const requestAddChestLink = (link) => {
    return axios.post(CREATE_TOOL_CHEST_LINK, link);
};

export const requestChestLinkList = () => {
    return axios.get(TOOL_CHEST_LINK_LIST);
};

export const deleteLink = (payload) => {
    return axios.post(CREATE_TOOL_CHEST_LINK, payload);
};