import { SearchIcon } from "assets/icons/Icons";
import CardCategory from "components/category";
import Layout from "components/layout";
import Loading from "components/Loading";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { requestCategoryID } from "services/bestpractice";
import { TITLE_BP } from "utils/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import previousPageAction from "redux/actions/previousPageAction";

function Category() {
  let params = useParams();
  const dispatch = useDispatch();
  const authInfo = useSelector((state) => state.auth);

  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [keySearch, setKeySearch] = useState(null);
  const [page, setPage] = useState(0);
  const [categoryDetail, setCategoryDetail] = useState([]);

  const getFirstDetailCategory = () => {
    setCategoryDetail([]);
    setPage(0);
    getDetailCategory();
  };

  const getDetailCategory = async () => {
    if (page !== -1) {
      setLoading(true);
      try {
        const { data } = await requestCategoryID({
          params: {
            category_id: params?.id,
            offset: page * 50,
            search: keySearch,
          },
        });


        if (data?.data?.length > 0) {
          setCategoryDetail([...categoryDetail, ...data.data]);
          setPage(page + 1);
        } else {
          setPage(-1);
        }
      } catch (error) {
        console.log("error", error);
      }
      setLoading(false);
    }
  };

  const handleSearch = debounce((event) => {
    const keySearch = event.target.value;
    setPage(0);
    setCategoryDetail([]);
    setKeySearch(keySearch);
  }, 500);

  const setPreviousURL = () => {
    dispatch(
      previousPageAction.addPreviousURL(
        `/best-practice/${location?.state?.categoryId}`
      )
    );
  };

  useEffect(() => {
    getDetailCategory();
  }, [keySearch]);

  
  return (
    <Layout title={TITLE_BP}>
      <Loading isVisible={loading} />

      <div id="nav2">
        <Link to="/dashboard" className="ms-4 mx-1">Calendar</Link>/
        <Link to="/tool-chest" className="mx-1">Tool Chest</Link>/
        <Link to="/best-practice" className="active ms-1">
          Best Practices
        </Link>
      </div>

      <div className="container">
        <Link to="/best-practice" className="text-white no-underline">
          Best Practices
        </Link>
        <span> / </span>
        <span className="text-white no-underline">
          {location?.state?.title}
        </span>

        <div className="flex float-right space-x-4">
          <div className="flex items-center bg-white rounded-md">
            <div className="pl-2 text-blue-10 border-r">
              <SearchIcon />
            </div>
            <input
              type="text"
              placeholder="Search"
              className="text-base text-black-10 p-1 outline-none rounded-md w-[200px] px-3"
              onChange={handleSearch}
            />
          </div>
          {authInfo?.role !== "viewer" && (
            <Link
              onClick={setPreviousURL}
              to="/best-practice/add-article"
              className="no-underline bg-green-10 py-2 px-3 text-white font-bold rounded-full text-sm min-w-[90px] hover:opacity-80 shadow-lg duration-500 truncate"
            >
              Add New Article
            </Link>
          )}
        </div>

        <InfiniteScroll
          className="space-y-6 mt-10"
          dataLength={categoryDetail.length}
          next={getDetailCategory}
          //hasMore={true}
        >
          {categoryDetail.map((el, index) => (
            <div key={index}>
              <CardCategory
                data={el}
                getFirstDetailCategory={getFirstDetailCategory}
              />
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </Layout>
  );
}

export default Category;
