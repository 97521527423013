import './ChestListItem.css'

const description = (item) => {
    if(item.description){
        return (
            <p>{item.description}</p>
        )
    } else if (item.metadata_info.description){
        return (
            <p>{item.metadata_info.description}</p>
        )
    } else {
        return (
            <p>No description provided.</p>
        )
    }
}

export const ChestListItem = ({item, dropItem}) => {
    return (
        <div className="chest-item-container" key={item.uuid}>
            <div className="chest-item-header">
                <p>{item.title}</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="1.75em" height="1.75em" viewBox="0 0 256 256" onClick={() => dropItem(item.uuid)}><path fill="#ffffff" d="M216 50h-42V40a22 22 0 0 0-22-22h-48a22 22 0 0 0-22 22v10H40a6 6 0 0 0 0 12h10v146a14 14 0 0 0 14 14h128a14 14 0 0 0 14-14V62h10a6 6 0 0 0 0-12M94 40a10 10 0 0 1 10-10h48a10 10 0 0 1 10 10v10H94Zm100 168a2 2 0 0 1-2 2H64a2 2 0 0 1-2-2V62h132Zm-84-104v64a6 6 0 0 1-12 0v-64a6 6 0 0 1 12 0m48 0v64a6 6 0 0 1-12 0v-64a6 6 0 0 1 12 0"></path></svg>
            </div>
            <div className="chest-item-body">
                {description(item)}
                <a href={item.url} target="_blank" rel="noopener noreferrer">Visit Link</a>
            </div>
        </div>
    )
}