import './BigCalendar.css';
import {Calendar, dateFnsLocalizer} from 'react-big-calendar';
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'
import {useState, useEffect} from 'react'


const locales = {
    'en-US': enUS,
}
  
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})

export const BigCalendar = (props) => {
    const {events, windowdimensions, role, onSlotSelect, onEventSelect, date, navigationHandler} = props;

    const [customViews, setCustomViews] = useState(['day']);
    const [defaultView, setDefaultView] = useState('day');

    useEffect(() => {
        if(windowdimensions.width >= 768){
            setCustomViews(['day', 'week', 'month']);
            setDefaultView('month')
        } else {
            setCustomViews(['day']);
            setDefaultView('day')
        }
    }, [windowdimensions.width]);

    const handleSelectSlot = (slotInfo) => {
        onSlotSelect(slotInfo);
    }

    const handleSelectEvent = (event) => {
        onEventSelect(event);
    }

    return(
        <div className='calendar-container'>
            <Calendar
                localizer={localizer}
                startAccessor="start"
                endAcessor="end"
                events={events}
                view={defaultView}
                onView={setDefaultView}
                views={customViews}
                selectable={role !== 'viewer' ? true : false}
                onSelectSlot={handleSelectSlot}
                onSelectEvent={handleSelectEvent}
                className='calendar'
                date={date}
                onNavigate={navigationHandler}
                step={60}
                timeslots={1}
            />
        </div>
    )
}