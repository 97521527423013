import { useSelector } from "react-redux";
import Layout from "components/layout";
import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { TITLE_MY_PROFILE } from "utils/constants";

import { IconCheck, IconClose, IconInfo } from "@aws-amplify/ui-react";
import { useForm } from "react-hook-form";
import { notifyError, notifySuccess } from "utils/helper";
import { ToastContainer } from "react-toastify";

export default function MyProfile() {
  const { register, handleSubmit, reset } = useForm();

  const [password, setPassword] = useState("");
  const change_password_fields = [
    { label: "Old password", name: "old_password" },
    { label: "New password", name: "new_password" },
    { label: "Confirm password", name: "confirm_password" },
  ];

  const onChangePassword = (e, field) => {
    if (field?.name === "new_password") {
      setPassword(e.target.value);
    }
  };

  const onSubmit = async (data) => {
    console.log("🚀 ===== data", data);
    if (data?.new_password && data?.new_password && data?.new_password) {
      if (data?.new_password !== data?.confirm_password) {
        notifyError(
          "Password and Confirm Password do not match. Please try again."
        );
        return;
      }

      if (data?.new_password?.length <= 12) {
        notifyError("Password must be more than 12 characters.");
        return;
      }

      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, data?.old_password, data?.new_password);
        reset({
          old_password: "",
          new_password: "",
          confirm_password: "",
        });
        notifySuccess("Your password is updated successfully");
        return;
      } catch (error) {
        console.log("🚀 ===== error", error?.message);
        notifyError(error?.message);
      }
    } else {
      notifyError("You must fill in all fields.");
    }
  };

  const authInfo = useSelector((state) => state.auth);

  return (
    <Layout title={TITLE_MY_PROFILE}>
      <ToastContainer />
      {authInfo.role !== "viewer" ? 
      <div id="nav2">
        <a href="/my-profile" className="active ms-4 me-1">
          My Info
        </a>/
        <a href="/my-stuff" className="ms-1">
          My Stuff
        </a>
      </div> : ""}
      <form onSubmit={handleSubmit(onSubmit)} className="container-fluid d-flex justify-content-center align-items-center">
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            <div className="profBox" style={{backgroundColor: "#e0d3de"}}>
              <div>
                <label htmlFor="rol" className="mb-1">
                  Role:
                </label>
                <input
                  type="text"
                  name="role"
                  id="user_role"
                  className="inputBox"
                  placeholder={authInfo.role}
                  readOnly
                />
              </div>
              <div>
                <label htmlFor="email" className="mt-3 mb-1">
                  Email:
                </label>
                <input
                  type="email"
                  name="email_add"
                  id="email_add"
                  className="inputBox"
                  placeholder={authInfo.email}
                  readOnly
                />
              </div>
              <div>
                <label htmlFor="passwords" className="mt-3 mb-1">
                  Change Password:
                </label>
                {change_password_fields.map((field) => (
                  <div className="relative" key={field.name}>
                    <input
                      onChange={(e) => onChangePassword(e, field)}
                      type="password"
                      name={field.name}
                      id={field.name}
                      className="inputBox PWord mb-3"
                      placeholder={field.label}
                      {...register(field.name)}
                    />
                    {/* tooltip */}
                    {field?.name === "new_password" && (
                      <div className="absolute z-50 right-2 text-gray-500 top-2 group">
                        <IconInfo />
                        <div className="hidden group-hover:block absolute right-0 w-80 p-4 shadow-lg bg-slate-50 text-black-10 rounded-md">
                          <div className="flex">
                            {password?.length > 12 ? (
                              <div className="text-green-500">
                                <IconCheck />
                              </div>
                            ) : (
                              <div className="text-red-500">
                                <IconClose />
                              </div>
                            )}
                            Over 12 characters
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div>
                <label htmlFor="phone_number" className="mb-1">
                  Cell Phone Number:
                </label>
                <input
                  type="text"
                  name="phone_number"
                  id="phone_number"
                  className="inputBox"
                  placeholder="phone number"
                />
              </div>
              <div className="checkBox mt-2 mb-4">
                <input id="cellphone" className="border" type={"checkbox"} />
                <label className="checkLabel" htmlFor="cell">
                  <p>
                    By providing my cell phone number I consent to receive calls
                    (including recorded or autodialed calls, or texts) at that
                    number from AFSCME and its affiliated labor, political and
                    charitable organizations on any subject matter. My carrier’s
                    rates may apply. I may modify my preferences by visiting{" "}
                    <a href="https://www.afscme.org/tcpa/">
                      www.afscme.org/tcpa.
                    </a>{" "}
                  </p>
                </label>
              </div>
              <div className="d-flex justify-content-end text-white">
                <button type="submit" className="inputSave">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Layout>
  );
}
