import React, {useState} from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

/*ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      //"link",
      "|",
      "bulletedList",
      "numberedList",
      //"|",
      //"mediaEmbed",
      "|",
      "undo",
      "redo",
    ],
  },
};*/
const config = {
  heading: {
      options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
          { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
      ],
  },
  readOnly: false,  // Set to true to make the editor read-only
  toolbar: [
      'undo', 'redo', '|', 'heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', '|', 'blockQuote'
  ],
  removePlugins: ['ImageInsert', 'ImageUpload', 'EasyImage']
};


function Editor({ data, onChange }) {

  const handleChange = (event, editor) => {
    const data = editor.getData();
    if(onChange){
      onChange(data)
    }
  }

  return (
      <CKEditor editor={ClassicEditor} config={config} data={data} onChange={handleChange}/>
    
  );
}

export default Editor;
