import Layout from "components/layout";
import BestPracticeForm from "components/best-practice";
import {Navbar} from "react-bootstrap";
import React from "react";
import { TITLE_BP } from "utils/constants";
import { Link } from "react-router-dom";
import { UserPageContainer } from "components/layout/UserPageContainer/UserPageContainer";
import { MainContentContainer } from "components/layout/MainContentContainer/MainContentContainer";
import {BestPracticesContainer} from 'components/CategoryMgmt/BestPracticesContainer/BestPracticesContainer'

export default function BestPractice() {
  const BreadCrumbsMenu = [
    {menuName: 'Calendar', menuLink: '/dashboard'},
    {menuName: 'Tool Chest', menuLink: '/tool-chest'},
    {menuName: 'Best Practices', menuLink: '/best-practice'}
  ]

return (
      <UserPageContainer breadcrumbs={BreadCrumbsMenu}>
        <MainContentContainer>
          <BestPracticesContainer/>
        </MainContentContainer>
      </UserPageContainer>
  );
}

{/* <Layout title={TITLE_BP}>
  <Navbar id="nav2" style={{paddingLeft: '2em'}}>
    <a href='./dashboard' className="ms-4 mx-1">Calendar</a>/
    <a href='./tool-chest' className="mx-1">Tool Chest</a>/
    <Link to='/best-practice' className="active ms-1">Best Practices</Link>
  </Navbar>
  <BestPracticeForm/>
</Layout> */}