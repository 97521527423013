
import React, {useState} from "react";
import UserList from "components/user_list";

export default function ViewUsers() {
  const [selected_view, setSelectedView] = useState('block')
  return (
      <UserList  view={selected_view} setView={setSelectedView} />
  );
  }

