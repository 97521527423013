
import React, { useEffect, useState, useCallback } from "react";
import {EventModal} from '../../components/EventModal/EventModal'
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import AddEvents from "./AddEvents";
import { requestGetCalendarEvent } from "services/calendar";
import UpdateEvent from "./UpdateEvents";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSelector } from "react-redux";
import { UserPageContainer } from "components/layout/UserPageContainer/UserPageContainer";
import { MainContentContainer } from "components/layout/MainContentContainer/MainContentContainer";
import {BigCalendar} from "components/Calendar/BigCalendar"

export default function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [searchDate, setSearchDate] = useState(null);

  // const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  const [allEvents, setAllEvents] = useState([]);

  const [selectedEvent, setSelectedEvent] = useState();


  const authInfo = useSelector((state) => state.auth);

  const getCalendarEvent = useCallback(async (date) => {


    const currentDay = new Date();
    let month = currentDay.getMonth() + 1;
    let year = currentDay.getFullYear(); 

    if (date) {
      const searchDate = new Date(date);

      month = searchDate.getMonth() + 1;
      year = searchDate.getFullYear();
    } 

    setLoading(true);
    try {
      const { data } = await requestGetCalendarEvent({
        params: { month: month, year: year },
      });
      if (data && data?.data) {
        if (authInfo.aff_pk === "14429") {
          const events = data.data.map((event) => {
            return {
              ...event,
              allDay: false,
              start: new Date(event.start_date),
              end: new Date(event.end_date),
            };
          });
          setAllEvents(events);
        } else {
          const events = data.data
            .filter((event) => 
              event.aff_pk === "14429" ||
              authInfo.role === "system_admin" ||
              event.aff_pk === authInfo.aff_pk
            )
            .map((event) => ({
              ...event,
              allDay: false,
              start: new Date(event.start_date),
              end: new Date(event.end_date),
            }));
          setAllEvents(events);
        }
      }
    } catch (error) {
      console.error("error", { ...error });
    }
    setLoading(false);
  }, [authInfo]);

  const handleSlotSelect = (data) => {
    if(data){
      setSelectedEvent(null);
      setIsModalOpen(true);
      //setSearchDate(data.start)
    }
  };

  const handleEventSelect = (data) => {
    console.log(`Update event data: ${JSON.stringify}`)
    if(data){
      setIsModalOpen(true);
      setSelectedEvent(data);
    }
  };

  const handleSearchDate = (date) => {
    setSearchDate(date);
    getCalendarEvent(date);
  };

  useEffect(() => {
    getCalendarEvent();
  }, [getCalendarEvent])


  const BreadCrumbsMenu = [
    {menuName: 'Calendar', menuLink: '/dashboard'},
    {menuName: 'Tool Chest', menuLink: '/tool-chest'},
    {menuName: 'Best Practices', menuLink: '/best-practice'}
  ]

  const[isModalOpen, setIsModalOpen] = useState(false);

  const displayModal = () => {
    return (
      <EventModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {selectedEvent ? (
          <UpdateEvent
          setLoading={setLoading}
          data={selectedEvent}
          onClose={() => setIsModalOpen(false)}
          getCalendarEvent={getCalendarEvent}
        />
        ) : (
          <AddEvents
          setLoading={setLoading}
          onClose={() => setIsModalOpen(false)}
          getCalendarEvent={getCalendarEvent}
        />
        )}
      </EventModal>
    ); 
  };

  return (
      <UserPageContainer breadcrumbs={BreadCrumbsMenu}>
          {displayModal()}
          <MainContentContainer>
            <BigCalendar events={allEvents} 
                         setEvents={setAllEvents} 
                         role={authInfo.role} 
                         onSlotSelect={handleSlotSelect}
                         onEventSelect={handleEventSelect}
                         date={searchDate}
                         navigationHandler={handleSearchDate}/>
          </MainContentContainer> 
      </UserPageContainer>
    )
  }

