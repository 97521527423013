import { combineReducers } from "redux";
import { aff_pkReducer } from "./aff_pk";
import { authReducer } from "./auth";
import { userDeleteReducer } from "./userDelete";
import errorReducer from "./errorReducer";
import EventReducer from "./eventReducer";
import EventsReducer from "./eventsReducer";
import modalReducer from "./modalReducer";
import { bestPracticesReducer } from "./bestPracticesReducer";
import { previousPageReducer } from "./previousPageReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  aff_pk: aff_pkReducer,
  userDelete: userDeleteReducer,
  event: EventReducer,
  events: EventsReducer,
  modalStatus: modalReducer,
  error: errorReducer,
  bestPractices: bestPracticesReducer,
  previousPage: previousPageReducer,
});

export default rootReducer;
