

export const AFFILIATE_STATE_LIST = process.env.REACT_APP_API + "/v1/list-affiliates";/* FIXME: */
export const REQUEST_USER_ACCESS = process.env.REACT_APP_API + "/v1/new-access-request"; /* FIXME: */
export const PENDING_REQUEST_LIST = process.env.REACT_APP_API + "/v1/query-access-requests"; /* FIXME: */
export const RESPONSE_PENDING_REQUEST = process.env.REACT_APP_API + "/v1/dispose-access-request"; /* FIXME: */
export const REQUEST_USER_REGISTRATION = process.env.REACT_APP_API + "/v1/create-new-user";
export const CREATE_TOOL_CHEST_LINK = process.env.REACT_APP_API + "/v1/post-tool-links";
export const TOOL_CHEST_LINK_LIST = process.env.REACT_APP_API + "/v1/query-tool-links";
export const USER_LIST = process.env.REACT_APP_API + "/v1/query-users-admin";
export const MANAGE_USER_ACCESS = process.env.REACT_APP_API + "/v1/manage-user-access";
export const BEST_PRACTICE_CATEGORY = process.env.REACT_APP_API + "/v1/query-categories";
export const BEST_PRACTICE_CATEGORY_DETAIL = process.env.REACT_APP_API + "/v1/query-best-practices";
export const MANAGE_BEST_PRACTICES =  process.env.REACT_APP_API + "/v1/manage-best-practices";
export const PRESIGNED= process.env.REACT_APP_API + '/v1/presigned';

export const CREATE_CATEGORY  = process.env.REACT_APP_API + '/v1/post-categories';
export const MANAGE_CALENDAR = process.env.REACT_APP_API + '/v1/manage-calendar-requests';
export const CALENDAR_EVENT = process.env.REACT_APP_API + '/v1/query-calendar-requests';
export const ALL_ARTICLES = process.env.REACT_APP_API + '/v1/query-best-practices?all=true';
export const GET_AFFILIATE_INFO = process.env.REACT_APP_API + '/v1/get-affiliate-info';




