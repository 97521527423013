import React, { useEffect, useState, useRef, useCallback} from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import Select from "react-select";
import { requestAddCalendarEvent } from "services/calendar";
import Loading from "components/Loading";
import DatePicker from "react-datepicker";
import { notifyError, readFileArrayBuffer } from "utils/helper";
import {
  fetchAffliatessAPI,
  fetchStateAPI,
  getAffiliateInfo,
} from "services/register";
import { requestPresigned } from "services/bestpractice";
import axios from "axios";
import { IconDownload } from "@aws-amplify/ui-react";
import { CloseIcon } from "assets/icons/Icons";
import { useSelector } from "react-redux";

const schema = yup
  .object({
    title: yup.string().required("Can't Be Empty"),
  })
  .required();

const UpdateEvent = ({ onClose, data, getCalendarEvent }) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const authInfo = useSelector((state) => state.auth);

  const [dataState, setDataState] = useState([]);
  const [selectState, setSelectState] = useState({});

  const [dataAffiliates, setDataAffiliates] = useState([]);
  const [selectAffiliates, setSelectAffiliates] = useState("");

  const [listFileUpload, setListFileUpload] = useState([]);

  const [IUEvent, setIUEvent] = useState(
    data.aff_pk === 14429 ? 'YES' : 'NO'
    
  );


  useEffect(() => {
    setIUEvent(data.aff_pk === 14429 ? 
      'YES' : 'NO')
  }, [data.aff_pk])

  console.log(`default value: ${IUEvent}`)
  const toggleIUEvent = (value) => {
    setIUEvent(value);
  };

  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    }
  }, [])

  const onSubmit = async (values) => {
    setLoading(true);
    const endDate = values?.end;
    const startDate = values?.start;
    const aff_pk =
      authInfo?.role === "affiliate_admin"
        ? +authInfo?.aff_pk
        : IUEvent === "YES"
        ? 14429
        : selectAffiliates?.aff_pk;

    if (endDate > startDate) {
      try {
        const listURL = await Promise.all(
          listFileUpload.map(async (item) => {
            const responsePresigned = await requestPresigned({
              data: {
                object_key: item?.name,
                content_type: item?.type,
                action: "putObject",
              },
            });
            const signedUrl = responsePresigned.data;
            return signedUrl;
          })
        );
        const fileData = await Promise.all(
          listFileUpload.map(async(file) => {
            try {
              if(!file.blob || !(file.blob instanceof Blob)){
                throw new Error(`Invalid blob for file: ${file.name}`)
              }

              return await readFileArrayBuffer(file.blob)
            } catch(err){
              console.error(`Error occured on reading file buffer: ${file.name}`);
              return null;
            }
            
          })
        );

        await Promise.all(
          fileData.map(async (d, index) => {
            try{
              if(!d || !listURL[index]){
                throw new Error(`File data or URL is undefined for index: ${index}`)
              }

              await axios({
                method: "put",
                url: listURL[index],
                data: d,
                headers: { "Content-Type": listFileUpload[index]?.type },
              });

            } catch(err){
              console.error(`Error uploading file data to URL: ${listURL[index]}: ${err}`)
            }

          })
        );

        await requestAddCalendarEvent({
          data: {
            update: [
              {
                uuid: data?.uuid,
                title: values?.title,
                description: values?.describe,
                start_date: values?.start,
                end_date: values?.end,
                owner: data?.owner,
                timestamp: data?.timestamp,
                upload_urls:
                  listFileUpload?.length > 0 ? listURL : data?.upload_urls,
                aff_pk: aff_pk,
              },
            ],
          },
        });

        onClose();
      } catch (error) {
        const errorMessage = error?.response?.data?.error?.message;

        notifyError(
          errorMessage ? errorMessage : `Data Fetch Error ${error.status}`
        );

        setLoading(false);
      }
    } else {
      notifyError("End date/time should be greater than start date/time");
      setLoading(false);
      return;
    }

    await getCalendarEvent();
    if(isMounted.current){
      setLoading(false);
    }
  };

  const onDeleteEvent = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await requestAddCalendarEvent({
        data: {
          delete: [
            {
              uuid: data?.uuid,
            },
          ],
        },
      });
      onClose();
      setLoading(false);
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );

      setLoading(false);
    }
    getCalendarEvent();
  };

    // ref to input file type;
  const fileInputRef = useRef(null);

  const handleSelectFile = (event) => {
    if(fileInputRef.current && fileInputRef.current.files.length > 0){
      const file = fileInputRef.current.files[0];

      setListFileUpload(prevState => {
        const fileExists = prevState.some(f => 
          f.name === file.name && f.type === file.type
        );

        if(!fileExists){
          return [...prevState, {blob: file, name: file.name, type: file.type}];
        }

        return prevState
      });
    }
  };

  const handleSelectState = (event) => {
    setSelectState(event);
    setSelectAffiliates("");
  };

  const handleSelectAffliates = (event) => {
    setSelectAffiliates(event);
  };

  const getAffiliateByAff_pk = useCallback(async () => {
    if (data?.aff_pk === "14429") return;
    try {
      const response = await getAffiliateInfo({
        params: { aff_pk: data?.aff_pk },
      });

      setSelectState({
        label: response?.data?.state,
        value: response?.data?.state,
      });
      setSelectAffiliates({
        label: response?.data?.affiliateAssoc,
        value: response?.data?.affiliateAssoc,
      });
    } catch (error) {
      console.log("🚀 ===== error", error);
    }
  }, [data.aff_pk]);

  const getStates = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetchStateAPI();

      if (!response?.data) return;
      const selectStateData = response?.data.map((item) => {
        return { value: item?.state, label: item.state };
      });

      setDataState(
        selectStateData.sort((a, b) => {
          if (a.value > b.value) {
            return 1;
          }
          if (b.value > a.value) {
            return -1;
          }
          return 0;
        })
      );
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error", error);
    }
    setLoading(false);
  }, [setDataState]);

  const getAffiliates = useCallback(async () => {
    try {
      const response = await fetchAffliatessAPI({
        data: { state: selectState.value },
      });

      if (response && response.data) {
        if (response.data.length === 0) {
          setDataAffiliates([]);
        } else {
          var filteredAffliatedData = response?.data;
          filteredAffliatedData = filteredAffliatedData.filter((x) => x.type === "C" || x.type === "R" || x.type === "L");
          const selectAffliatesData = filteredAffliatedData
            .sort((a, b) => {
              if (a.short_name > b.short_name) {
                return 1;
              }
              if (b.short_name > a.short_name) {
                return -1;
              }
              return 0;
            })
            .map((item) => {
              return {
                ...item,
                value: item?.short_name,
                label: item?.short_name,
                affPk: item?.aff_pk,
                parent: item?.parent,
              };
            });

          setDataAffiliates([...selectAffliatesData]);
        }
      }
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error:", error);
    }
  }, [selectState.value, setDataAffiliates]);

  const removeFile = (file) => {
    const listFiles = [];

    listFileUpload.forEach((item) => {
      if (item?.name !== file?.name) {
        listFiles.push(item);
      }
    });

    setListFileUpload(listFiles);
  };

  useEffect(() => {
    setValue("title", data?.title);
    setValue("describe", data?.description);
    setValue("start", new Date(data?.start_date));
    setValue("end", new Date(data?.end_date));
    setValue("IUEvent", IUEvent);
    getStates();
    getAffiliateByAff_pk();
  }, [data.title, data.description, data.start_date, data.end_date, IUEvent, setValue, getAffiliateByAff_pk, getStates]);

  useEffect(() => {
    if (selectState) {
      getAffiliates();
    }
  }, [selectState, getAffiliates]);

  return (
    //this form is in bootstrap
    <>
      <Loading isVisible={loading} className="loader"/>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-field">
                  <label htmlFor="title" className="form-label">
                    Event Title:
                  </label>
                  <input
                    {...register("title")}
                    type="text"
                    placeholder="Title"
                    className="form-control"
                    id="title"
                    aria-describedby="title"
                  />
                  {errors.title ? <p>{errors.title.message}</p> : ''}
                </div>

                <div className="form-field">
                  <label htmlFor="start" className="form-label">
                    Start Date:
                  </label>
                  {/* controllers are the way you can wrap and use datePicker inside react form-hook*/}
                  {/* start date controller*/}
                  <Controller
                    control={control}
                    name="start"
                    render={({ field }) => (
                      <DatePicker
                        placeholderText="Select date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        value={field.value}
                        showTimeSelect
                        timeFormat="HH:mm"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        className="form-control"
                        id="start"
                        autoComplete="off"
                      />
                    )}
                  />
                  {errors.start ? <p>{errors.start.message}</p> : ''}
                </div>
                <div className="form-field">
                  <label htmlFor="end" className="form-label">
                    End Date:
                  </label>
                  {/* end date controller*/}
                  <Controller
                    control={control}
                    name="end"
                    render={({ field }) => (
                      <DatePicker
                        placeholderText="Select end date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        value={field.value}
                        timeFormat="HH:mm"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        showTimeSelect
                        className="form-control"
                        id="end"
                        autoComplete="off"
                      />
                    )}
                  />
                </div>

                <div className="form-field">
                  <label htmlFor="describe" className="form-label">
                    Event Description: {" "}
                    <span>(optional)</span>
                  </label>
                  <input
                    {...register("describe")}
                    type="text"
                    placeholder="describe your event"
                    className="form-control"
                    id="describe"
                    aria-describedby="describe"
                  />
                </div>

                <div className="form-field" id="iu-select">
                {authInfo.role === "system_admin" && (
                  <>
                    <label htmlFor="title" className="form-label">
                      IU Event?:
                    </label>
                    <input
                      {...register("IUEvent")}
                      id="IUEventT"
                      type="radio"
                      value="YES"
                      checked={IUEvent === 'YES'}
                      onChange={() => toggleIUEvent("YES")}
                    />
                    <label
                      htmlFor="IUEventT"
                    >
                      YES
                    </label>

                    <input
                      {...register("IUEvent")}
                      id="IUEventF"
                      type="radio"
                      value="NO"
                      checked={IUEvent === 'NO'}
                      onChange={() => toggleIUEvent("NO")}
                    />
                    <label
                      htmlFor="IUEventF"
                    >
                      NO
                    </label>
                  </>
                )}
                </div>

                {data.aff_pk === "14429" && authInfo.role !== "system_admin" ? null :
                <><div className="form-field">
                  <Controller
                    name="state"
                    render={({ field }) => (
                      <Select
                        name="selectState"
                        className="selectBox"
                        {...field}
                        options={dataState}
                        onChange={handleSelectState}
                        value={
                          authInfo?.role === "affiliate_admin"
                            ? {
                                label: authInfo?.state,
                                value: authInfo?.state,
                              }
                            : selectState
                        }
                        placeholder="State"
                        tabIndex={7}
                        isDisabled={
                          IUEvent === "YES" || authInfo?.role !== "system_admin"
                        }
                      />
                    )}
                    control={control}
                  />
                </div>

                <div className="form-field">
                  <Controller
                    name="affliates"
                    render={({ field }) => (
                      <Select
                        className="selectBox"
                        {...field}
                        options={dataAffiliates}
                        isDisabled={
                          selectState.length === 0 ||
                          IUEvent === "YES" ||
                          authInfo?.role !== "system_admin"
                        }
                        onChange={handleSelectAffliates}
                        value={
                          authInfo?.role === "affiliate_admin"
                            ? {
                                label: authInfo?.affiliate_assoc,
                                value: authInfo?.affiliate_assoc,
                              }
                            : selectAffiliates
                        }
                        placeholder="Affiliate"
                        tabIndex={8}
                      />
                    )}
                    control={control}
                  />
                </div>
                </>}

                {/* Upload file */}
                <div className="form-field">
                  <input type="file" ref={fileInputRef} onChange={handleSelectFile} />
                    {listFileUpload.map((file) => {
                      return (
                        <div key={file?.name}>
                          <p> {file.name}</p>
                          <div onClick={() => removeFile(file)}>
                            <CloseIcon size={24} />
                          </div>
                        </div>
                      );
                    })}
                  

                  {data?.upload_urls?.length > 0 && (
                      <div className="form-field" id="downloadable">
                        {data?.upload_urls?.map((url) => {
                          return (
                            <div key={url} id="downloadable-item">
                              <a
                                href={url}
                                target="_blank"
                                rel="noreferrer"
                              ><IconDownload /></a>
                              <p>
                                {decodeURIComponent(
                                  url
                                    ?.split("/")
                                    ?.[url?.split("/")?.length - 1]?.split(
                                      "?"
                                    )[0]
                                )}
                              </p>
                             
                            </div>
                          );
                        })}
                      </div>
           
                  )}
                </div>
                
                {(data.aff_pk !== "14429" && authInfo.role !== "system_admin") || (authInfo.role === "system_admin") ? 
                <div className="form-field" id="btn-group">
                  <button type="submit">
                    Update
                  </button>
                  <button onClick={onDeleteEvent}>
                    Delete
                  </button>
                </div>
                :
                null}
              </form>
    </>
  );
};

export default UpdateEvent;
