import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import Editor from "components/editor";

import Layout from "components/layout";
import Loading from "components/Loading";
import { TITLE_BP } from "utils/constants";
import {
  requestAddArticle,
  requestCategory,
  requestPresigned,
} from "services/bestpractice";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { notifySuccess, readFileArrayBuffer } from "utils/helper";
import axios from "axios";
import { BackIcon, CloseIcon } from "assets/icons/Icons";
import { requestChestLinkList } from "services/toolChest";
import { useSelector } from "react-redux";
// import { useLabels } from "@headlessui/react/dist/components/label/label";

function AddArticle() {
  let navigate = useNavigate();
  const { URL } = useSelector((state) => state.previousPage);
  const authInfo = useSelector((state) => state.auth);

  const [previousURL, setPreviousURL] = useState("");

  const {
    register,
    handleSubmit,
    //formState: { errors },
  } = useForm();

  const [listFileUpload, setListFileUpload] = useState([]);

  const [categoryItem, setcategoryItem] = useState([]);
  const [categorySelected, setCategorySelected] = useState(null);

  const [toolChestList, setToolChestList] = useState([]);
  const [toolChestSelected, setToolChestSelected] = useState(null);

  const [tab, setTab] = useState("tab1");
  const [htmlContent, setHtmlContent] = useState("");

  const [tag, setTag] = useState("");
  const [tags, setTags] = useState([]);

  const [loading, setLoading] = useState(true);

  const removeFile = (file) => {
    const listFiles = [];

    listFileUpload.forEach((item) => {
      if (item?.name !== file?.name) {
        listFiles.push(item);
      }
    });

    setListFileUpload(listFiles);
  };


  const fetchListCategory = async () => {
    setLoading(true);
    try {
      const response = await requestCategory();
      if (response.status === 200) {
        const dataSelect = response.data.data.map((item) => {
          // if (authInfo.role != "system_admin" && authInfo.aff_pk == item.aff_pk)
          //   return { label: item?.title, value: item };
          // else if (authInfo.role == "system_admin")
          return { label: item?.title, value: item };
        });
        const dataSelect2 = dataSelect.filter((item) => {
           return (item.value.aff_pk === authInfo.aff_pk);
            //return { label: item?.title, value: item };
        });  
        setcategoryItem(authInfo.role === "system_admin" ? dataSelect : dataSelect2);
      }
    } catch (error) {
      console.error("error", error);
    }
    setLoading(false);
  };

  const selectCategory = (value) => {
    setCategorySelected(value);
  };

  const selectToolChestLink = (value) => {
    setToolChestSelected(value);
  };

  const getToolChestList = async () => {
    try {
      const response = await requestChestLinkList();
      const dataSelect = response.data.map((item) => {
        return { label: item?.url, value: item };
      });

      setToolChestList(dataSelect);
    } catch (error) {
      console.error("🚀 ===== file: index.js ===== line 155 ===== error", error);
    }
  };

  const handleChangeEditor = (event, editor) => {
    const data = editor.getData();
    console.log(data);
    setHtmlContent(data);
  };

  const onSubmit = async(event) => {
    if (categorySelected === null) return;
    setLoading(true);
    let body = {
      title: event?.title,
      description: event?.description,
      tags: tags,
      body: htmlContent,
      category_id: categorySelected?.value?.uuid,
      tool_link_id: toolChestSelected?.value?.uuid,
    };

    try {
      if (listFileUpload.length > 0) {
        const listURL = await Promise.all(
          listFileUpload.map(async (item) => {
            try {
              const responsePresigned = await requestPresigned({
                data: {
                  object_key: item?.name,
                  content_type: item?.type,
                  action: "putObject",
                },
              });
    
              const signedUrl = responsePresigned.data;
              if (!signedUrl) {
                throw new Error(`Presigned URL is undefined for file: ${item?.name}`);
              }
              return signedUrl;
            } catch (error) {
              console.error(`Error fetching presigned URL for file: ${item?.name}`, error);
              return null; // Handle error case
            }
          })
        )

        const fileData = await Promise.all(
          listFileUpload.map(async(file) => {
            try {
              if(!file.blob || !(file.blob instanceof Blob)){
                throw new Error(`Invalid blob for file: ${file.name}`)
              }

              return await readFileArrayBuffer(file.blob)
            } catch(err){
              console.error(`Error occured on reading file buffer: ${file.name}`);
              return null;
            }
            
          })
        );

        
        await Promise.all(
          fileData.map(async (d, index) => {
            try{
              if(!d || !listURL[index]){
                throw new Error(`File data or URL is undefined for index: ${index}`)
              }

              await axios({
                method: "put",
                url: listURL[index],
                data: d,
                headers: { "Content-Type": listFileUpload[index]?.type },
              });

            } catch(err){
              console.error(`Error uploading file data to URL: ${listURL[index]}: ${err}`)
            }
          })
        );


        body = { ...body, supplemental_material_urls: listURL };
      }

      await requestAddArticle({
        data: {
          create: [body],
        },
      });

      notifySuccess("successfully");
      //window.location.href = "/best-practice/" + {categorySelected};
      navigate(`/best-practice/${categorySelected?.value?.uuid}`, {
        state: {
          title: selectCategory?.value?.title,
        },
      });
    } catch (error) {
      console.error("error", error);
    }

    setLoading(false);
  };

  const getHTMLContent = (event) => {
    setHtmlContent(event?.target?.value);
  };



  // ref to input file type;
  const fileInputRef = useRef(null);

  const handleSelectFile = () => {
    if(fileInputRef.current && fileInputRef.current.files.length > 0){
      const file = fileInputRef.current.files[0];

      setListFileUpload(prevState => {
        const fileExists = prevState.some(f => 
          f.name === file.name && f.type === file.type
        );

        if(!fileExists){
          return [...prevState, {blob: file, name: file.name, type: file.type}];
        }

        return prevState
      });
    }
  }; 

  const toggleTab = () => {
    if (tab === "tab1") setTab("tab2");
    if (tab === "tab2") setTab("tab1");
  };

  const setInputTag = (event) => {
    setTag(event.target.value);
  };

  const handleAddTags = (event) => {
    event.preventDefault();
    if (tags.length < 3 && tag) {
      setTags([...tags, tag]);
      setTag("");
    }
  };

  const removeTag = (item) => {
    const newTags = tags.filter((tag) => tag !== item);
    setTags(newTags);
  };


  useEffect(() => {
    if (URL) {
      setPreviousURL(URL);
    }
  }, [URL]);

  useEffect(() => {
    fetchListCategory();
    getToolChestList();
  }, []);


  return (
    <Layout title={TITLE_BP}>
      <Loading isVisible={loading} />
      <ToastContainer />
      <div id="nav2">
        <Link to="/dashboard" className="ms-4 mx-1">Calendar</Link>/
        <Link to="/tool-chest" className="mx-1">Tool Chest</Link>/
        <Link to="/best-practice" className="active ms-1">Best Practices</Link>
      </div>

      <div className="pb-10 container">
        <div className="flex items-center">
          <Link to={previousURL} className="cursor-pointer text-white">
            <BackIcon size={48} />
          </Link>
          <h3 className="flex-1 text-center my-8">NEW ARTICLE</h3>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="container mx-auto bg-blue-20 space-y-6 rounded-md"
        >
          {/* Input title + category */}
          <div className="flex gap-x-4">
            <input
              type="text"
              placeholder="Title"
              className="w-1/2 text-base font-bold text-black-10 p-1 outline-none rounded-md px-3 border-b"
              {...register("title")}
            />

            <Select
              className="w-1/2 text-black-10"
              options={categoryItem}
              placeholder="Category"
              onChange={selectCategory}
              required
            />
          </div>

          {/* Input description */}
          <input
            type="text"
            placeholder="Description"
            className="w-full text-base font-bold text-black-10 p-2 outline-none rounded-md px-3 border-b"
            {...register("description")}
          />

          <div className="text-sm text-center text-gray-500 dark:text-gray-400 dark:border-gray-700">
            <div className="flex">
              <div className="mr-2 cursor-pointer" onClick={toggleTab}>
                <p
                  className={
                    tab === "tab1"
                      ? "inline-block px-4 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500"
                      : "inline-block px-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                  }
                >
                  Editor
                </p>
              </div>
              <div className="mr-2 cursor-pointer" onClick={toggleTab}>
                <p
                  className={
                    tab === "tab2"
                      ? "inline-block px-4 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500"
                      : "inline-block px-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                  }
                >
                  HTML
                </p>
              </div>
            </div>

            {/* input html tag */}
            {tab === "tab2" && (
              <textarea
                className="w-full h-80 p-2 text-base font-bold text-black-10 outline-none rounded-sm px-3 border-b"
                placeholder="Enter HTML tag"
                name="htmlContent"
                value={htmlContent}
                onChange={getHTMLContent}
              />
            )}

            {/* text editor */}
            {tab === "tab1" && (
              <Editor data={htmlContent} handleChange={handleChangeEditor} />
            )}
          </div>

          {/* Upload file */}
          {/* <input type="file" multiple onChange={handleSelectFile} /> */}
          {/* Upload file */}
          <div className="mb-4">
            <input type="file" ref={fileInputRef} onChange={handleSelectFile}/>
            <div className="bg-white h-20 flex text-black-10 items-start gap-3 p-2 flex-wrap overflow-auto">
              {listFileUpload.map((file) => {
                return (
                  <div
                    key={file?.name}
                    className="bg-slate-300 rounded-sm flex"
                  >
                    <p className="px-2"> {file.name}</p>
                    <div
                      className="cursor-pointer"
                      onClick={() => removeFile(file)}
                    >
                      <CloseIcon size={24} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="flex gap-x-4">
            <div className="space-y-2 w-1/2">
              <div className="flex h-[38px]">
                {/* tag */}

                {/* Tags limit */}
                <input
                  type="text"
                  placeholder="Tags limit 3"
                  className="w-full text-base text-black-10 p-1 outline-none border-b rounded-l-md px-3"
                  value={tag}
                  onChange={setInputTag}
                />
                <button
                  onClick={handleAddTags}
                  className="rounded-r-md bg-blue-10 text-white font-bold text-sm min-w-[90px] hover:opacity-80 shadow-lg duration-500 truncate"
                >
                  Add tag
                </button>
              </div>
              <div
                className={`h-[38px] flex justify-start items-center px-2 py-1 space-x-2 rounded-md ${
                  tags?.length > 0 ? "bg-white" : "bg-gray-300"
                } `}
              >
                {tags?.length > 0 ? (
                  tags.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="flex justify-center items-center bg-slate-100 text-black-10 rounded-md"
                      >
                        <p className="p-0 m-0 px-2">{item}</p>
                        <div
                          className="cursor-pointer"
                          onClick={() => removeTag(item)}
                        >
                          <CloseIcon size={16} />
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <span className="text-gray-500 px-2">No Tags</span>
                )}
              </div>
            </div>

            <div className="w-1/2">
              <Select
                className="text-black-10"
                options={toolChestList}
                placeholder="Tool Chest Links"
                onChange={selectToolChestLink}
              />
            </div>
          </div>

          <div className="flex justify-end">
            <button className="bg-green-10 py-2 px-3 text-white font-bold rounded-full text-sm min-w-[90px] hover:opacity-80 shadow-lg duration-500 truncate">
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
}

export default AddArticle;
