import './MainContentContainer.css'
import React, { useLayoutEffect, useState, useEffect } from 'react'


export const MainContentContainer = ({children}) => {
    const calculateInitialDimensions = () => {
        if (typeof window === 'undefined') return { height: 0, width: 0 };
    
        const headerHeight = document.querySelector('nav')?.offsetHeight || 0;
        const breadCrumbHeight = document.querySelector('.breadcrumb-list')?.offsetHeight || 0;
        return {
          height: window.innerHeight - (headerHeight + breadCrumbHeight),
          width: window.innerWidth
        };
      };
    
    const [windowdimensions, setWindowDimensions] = useState(calculateInitialDimensions);

    useEffect(() => {
        const handleResize = () => {
            requestAnimationFrame(() => {
                const headerHeight = document.querySelector('nav')?.offsetHeight || 0;
                const breadCrumbHeight = document.querySelector('.breadcrumb-list')?.offsetHeight || 0;
    
                setWindowDimensions({
                    height: window.innerHeight - (headerHeight + breadCrumbHeight), 
                    width: window.innerWidth
                });
            })

        };

        // Initial update
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);


    return (
        <main style={{height: windowdimensions.height}}>
            <div className="content-container">
                {React.cloneElement(children, {windowdimensions})}
            </div>
        </main>
    )
}