import Layout from "components/layout";
import React from "react";
import { TITLE_REGISTRATION_FORM } from "utils/constants";
import UserRequests from "components/userRequest";
import { useSelector } from "react-redux";
import ViewUsers from "pages/view-users";

export default function Dashboard() {
  const authInfo = useSelector((state) => state.auth);
  return (
    <Layout title={TITLE_REGISTRATION_FORM}>
      <div className="space-y-8">
        {(authInfo.role === "system_admin" ||
          authInfo.role === "affiliate_admin") && <UserRequests />}
        <div className="pb-8 AdminUserList">
          <ViewUsers />
        </div>
      </div>
    </Layout>
  );
}
