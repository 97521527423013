export const previousPageTypes = {
  ADD_URL_PAGE: "ADD_ARTICLE",
  DELETE_URL_PAGE: "DELETE_ARTICLE",
};

export const bestPracticesTypes = {
  ADD_ARTICLE: "ADD_ARTICLE",
  DELETE_ARTICLE: "DELETE_ARTICLE",
};

export const loginActionTypes = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
};

export const tool_chest_request = {
  SUCCESS_MESSAGE: "Success! Tool chest link added.",
  ERROR_MESSAGE: "Error! Error occured while adding tool chest link.",
};

export const editAff_pkTypes = {
  ADD_AFF_PK: "ADD_AFF_PK",
  REMOVE_AFF_PK: "REMOVE_ADD_AFF_PK",
  CLEAR_AFF_PK: "CLEAR_AFF_PK",
};

export const userDeleteTypes = {
  ADD_USER_DELETE: "ADD_USER_DELETE",
  REMOVE_USER_DELETE: "REMOVE_USER_DELETE",
  CLEAR_USER_DELETE: "CLEAR_USER_DELETE",
};

export const logoutActionType = {
  LOGOUT: "LOGOUT",
};

export const TITLE_REGISTRATION_FORM = "Manage Users";
export const TITLE_TOOLCHEST = "Tool Chest";
export const TITLE_VIEWUSER = "View Users";
export const TITLE_BP = "Best Practice";
export const TITLE_MY_PROFILE = "My Profile";
export const TITLE_MESSAGE = "Message";
export const CREATE_USER = "Create User";

export const LOGIN_SUCCESS_MESSAGE = "You are logged in successfully";
export const SUCCESS_MESSAGE =
  "Thank you. Your user account request has been submitted to AFSCME for review";
export const SUCCESS_MESSAGE_ADMIN_USER_CREATE =
  "Success! User has been created.";
export const ERROR_MESSAGE =
  "We have experienced an unexpected error during the transmission of your registration. We have been notified of this error and our actively trying to rectify this issue. Please try again to register";
export const VALIDATE_ERROR_MESSAGE =
  "You should select at least one affiliate";

export const requestMessage = {
  APPROVE_SUCCESS_MESSAGE: "Successfully Approved",
  APPROVE_ERROR_MESSAGE: "Approve failed",
  DENY_SUCCESS_MESSAGE: "Successfully Denied",
  DENY_ERROR_MESSAGE: "Deny failed",
  INFORMATION_MESSAGE: "No pending requests",
};

export const REQUIRED_FIELD = "Please enter the required field";
export const REQUIRED_EMAIL = "Email must be a valid email";
export const REQUIRED_ALPHABET = "Only alphabets";

export const DATA_ROLES = [
  { value: "system_admin", label: "System Admin" , rank: 5},
  { value: "affiliate_admin", label: "Affiliate Admin", rank: 4},
  { value: "publisher", label: "Publisher", rank: 3 },
  { value: "user", label: "User", rank: 2 },
  { value: "viewer", label: "Viewer", rank: 1},
];
