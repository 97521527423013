import React from "react";
import Form from "./components/Form";
import UserForm from "components/layout/user_form";

function ForgotPassword() {
  return (
    <div>
    <UserForm>
      <Form />
    </UserForm>
    </div>
  );
}

export default ForgotPassword;