import logo from "assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import authActions from "redux/actions/auth";
import "bootstrap/dist/css/bootstrap.css";
import { Nav, Navbar, Container} from "react-bootstrap";
import "styles/engage-tool.css";
import PopupHelp from "components/PopupHelp";

export const CustomNavbar = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const authInfo = useSelector((state) => state.auth);
  const [modalOpen, setModalOpen] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(
      authActions.logout({
        isLoggedIn: false,
        role: null,
      })
    );
    navigate("/login");
  };


  function idleLogout() {
    var t;
    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer; // catches touchscreen presses as well
    window.ontouchstart = resetTimer; // catches touchscreen swipes as well
    window.ontouchmove = resetTimer; // required by some devices
    window.onclick = resetTimer; // catches touchpad clicks as well
    window.onkeydown = resetTimer;
    window.addEventListener("scroll", resetTimer, true); // improved; see comments

    function yourFunction() {
      handleLogout();
    }

    function resetTimer() {
      clearTimeout(t);
      t = setTimeout(yourFunction, 900000); // time is in milliseconds
    }
  }
  idleLogout();

  return (
    <Navbar expand='lg' className="custom-navbar">
      <Container fluid>
          <img src={logo} alt="Logo" className="afscme-logo"/>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
          <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={(modalOpen) ? 'is-disabled' : ''}>
            <Nav.Link href="/dashboard">HOME</Nav.Link>

            {authInfo.role === "affiliate_admin" && (
              <Nav.Link href="/affiliate-admin">ADMIN</Nav.Link>
            )}
            {authInfo.role === "system_admin" && (
              <Nav.Link href="/admin">
                  ADMIN
              </Nav.Link>
            )}
            <Nav.Link href="/my-profile">
              MY PROFILE
            </Nav.Link>

          <Nav.Link onClick={() => {
                      setModalOpen(true);
                  }}>
              HELP
            </Nav.Link>    
            
            <Nav.Link key="signout" onClick={handleLogout}>
              <div id="signout">
                SIGN OUT - Currently signed in: {" "}
                {authInfo.name ? authInfo.name.split(' ').map(name => name[0].toUpperCase() + name.slice(1)).join(' ') : ""}
              </div>
            </Nav.Link>
          </Nav>
          </Navbar.Collapse>
          {modalOpen && <PopupHelp setOpenModal={setModalOpen} /> }
      </Container>
    </Navbar>
  );
};

