import './UserPageContainer.css';
import {useState} from 'react';
import { CustomNavbar } from 'components/CustomNavbar';
import { BreadCrumbs } from 'components/BreadCrumbs/BreadCrumbs';
import { ToastContainer } from 'react-toastify';
import Loading from 'components/Loading';


export const UserPageContainer = ({loading, breadcrumbs, children}) => {
    return(
        <>
            <CustomNavbar/>
            {/* <Loading isVisible={loading} /> */}
             {/* <ToastContainer/> */}
            <BreadCrumbs menuItems={breadcrumbs}/>
            {children}
        </>
    )
}