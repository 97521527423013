import './BestPracticesCategory.css'
import {ArticleContainer} from '../ArticleContainer/ArticleContainer'
import {useState, useEffect} from 'react';
import {useCategoryManagmentContext} from '../context.tsx'
import {BestPracticeForm} from '../BestPracticeForm/BestPracticeForm'
import {LoadingState} from '../../LoadingState'


export const BestPracticesCategory = () => {
    const {categories, categoryID, setCategoryID, articles, articlesLoading, toolLinks, setFormSubmitSuccess} = useCategoryManagmentContext();

    const categoryIDHandler = (category_uuid) => {
        if(categoryID !== category_uuid){
            setViewArticle(false);
            setCategoryID(category_uuid);
        }
    }

    const categoryUUIDToTitleMap = new Map();

    categories.forEach(category => {
        categoryUUIDToTitleMap.set(category.uuid, category.title)
    })

    const [viewArticle, setViewArticle] = useState(false);
    const [currentArticle, setCurrentArticle] = useState();
    const [currentArticleIndex, setCurrentArticleIndex] = useState();
    const [newArticle, setNewArticle] = useState(false);

    useEffect(() => {
        const articleIndex = articles.findIndex(article => article.uuid === currentArticle);

        if(articleIndex !== -1){
            setCurrentArticleIndex(articleIndex);
            setNewArticle(false);
        }

    }, [articles, currentArticle]);

    const newArticleHandler = () => {
        setViewArticle(true);
        setCurrentArticle();
        setCurrentArticleIndex();
        setNewArticle(true);
    }

    const ArticleViewer = ({articlesLoading, articles, viewArticle, setViewArticle, categoryID}) => {
        if(articlesLoading){
            return(
                <LoadingState/>
            )
        }

        if(viewArticle){
        
            const articleSelection = articles[currentArticleIndex];

            const handleNext = () => {
                setCurrentArticleIndex((prevIndex) => 
                    prevIndex === articles.length - 1 ? 0 : prevIndex + 1
                )
            }

            const handlePrevious = () => {
                setCurrentArticleIndex((prevIndex) => 
                    prevIndex === 0 ? articles.length - 1 : prevIndex - 1
                )
            }

            //send this as a prop if you need title for the category for use in the form
            // const categoryTitle = categoryUUIDToTitleMap.get(categoryID);


            return(
                <div className="article-viewer">
                    <BestPracticeForm article={articleSelection} category={categoryID} newArticle={newArticle} toolLinks={toolLinks} setFormSubmitSuccess={setFormSubmitSuccess}/>
                    
                    <div className="article-navigation">
                        <button onClick={() => setViewArticle(!viewArticle)}><svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="m16.41 18.16l-5.66-5.66l5.66-5.66l.7.71l-4.95 4.95l4.95 4.95zm-4 0L6.75 12.5l5.66-5.66l.7.71l-4.95 4.95l4.95 4.95z"/></svg><span>Articles</span></button>

                        {newArticle ? null : (
                            <>
                            <button onClick={handlePrevious}><svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M14.41 18.16L8.75 12.5l5.66-5.66l.7.71l-4.95 4.95l4.95 4.95z"/></svg><span>Previous Article</span></button>
                            <button onClick={handleNext}><span>Next Article</span><svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="m8.59 18.16l5.66-5.66l-5.66-5.66l-.7.71l4.95 4.95l-4.95 4.95z"/></svg></button>
                            </>
                        )}

                    </div>
                </div> 
            )
        } else {
            return (
                <div className="articles-list" >
                {
                    articles.length > 0 ? (
                        <ul>
                            {articles.map(article => (
                            <ArticleContainer article={article} key={article.uuid} viewArticle={viewArticle} setViewArticle={setViewArticle} setCurrentArticle={setCurrentArticle}/>
                        ))}
                        </ul>
                    ) : <p id="no-articles">No articles.</p>
                }
                <button id="add-new-article" onClick={newArticleHandler}><svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 21 21"><g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"><path d="M10 4.5H5.5a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V11"/><path d="M17.5 3.467a1.46 1.46 0 0 1-.017 2.05L10.5 12.5l-3 1l1-3l6.987-7.046a1.41 1.41 0 0 1 1.885-.104zm-2 2.033l.953 1"/></g></svg>Add New Article</button>
            </div>
        )}
    }

    return(
        <div className="categories-container">
            
            {categories.map((cat) => {
                return(
                    <div className="category-bar" key={cat.uuid}>
                        <p id="category-title">{cat.title}</p>
                        {cat.description && <p id="category-description">{cat.description}</p>}
                        <div id='category-btn-group'>
                            {true && //set to role comparison with system_admin
                                <button className="btn btn-delete"><svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M7.616 20q-.672 0-1.144-.472T6 18.385V6H5V5h4v-.77h6V5h4v1h-1v12.385q0 .69-.462 1.153T16.384 20zM17 6H7v12.385q0 .269.173.442t.443.173h8.769q.23 0 .423-.192t.192-.424zM9.808 17h1V8h-1zm3.384 0h1V8h-1zM7 6v13z"/></svg>Delete Category</button>
                            }
                            {true && //define by number of articles?
                                <button className='btn btn-more' onClick={() => categoryIDHandler(cat.uuid)}><svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m12 20l6-6m-6 6l-6-6m6 6V9.5M12 4v2.5"/></svg>View Articles</button>
                            }
                        </div>
                            {/* Conditional rendering based on categoryID */}
                            {categoryID === cat.uuid && (
                                <ArticleViewer articlesLoading={articlesLoading} 
                                               articles={articles} 
                                               viewArticle={viewArticle} 
                                               setViewArticle={setViewArticle} 
                                               currentArticleIndex=   {currentArticleIndex} 
                                               setCurrentArticleIndex=   {setCurrentArticleIndex}
                                               categoryUUIDToTitleMap=   {categoryUUIDToTitleMap}
                                               categoryID={cat.uuid}
                                />

                            )}
                    </div>
                )
            })}
        </div>

    )

}