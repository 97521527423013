import { userDeleteTypes } from "utils/constants";

const addUserDelete = (payload) => ({
  type: userDeleteTypes.ADD_USER_DELETE,
  payload,
});

const removeUserDelete = (payload) => ({
  type: userDeleteTypes.REMOVE_USER_DELETE,
  payload,
});

const clearUserDelete = (payload) => ({
  type: userDeleteTypes.CLEAR_USER_DELETE,
  payload,
});

const UserDeleteActions = { addUserDelete, removeUserDelete, clearUserDelete };

export default UserDeleteActions;
