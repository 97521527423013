
import Layout from "components/layout";
import React from "react";
import { TITLE_MESSAGE } from "utils/constants";

export default function Message() {
  return (
    <Layout title={TITLE_MESSAGE}>
      <div className="mt-40"></div>
    </Layout>
  );
}
