import {useState} from 'react'
import {CustomNavbar} from 'components/CustomNavbar';
import 'styles/engage-tool.css';

const Layout = ({ children, title, className }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const MenuBtn = <button style={{ border: 'none'}} className="" onClick={() => {setSidebarOpen(!sidebarOpen);}}>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" stroke="#D6D6D6" className="h-8 w-8">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
  </svg>
</button>

const MenuCloseBtn = <button style={{ border: 'none' }} className="" onClick={() => {setSidebarOpen(!sidebarOpen);}}>
<svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="#D6D6D6">
  <path stroke-linecap="round" stroke-linejoin="round"  stroke-width="2" d="M6 18L18 6M6 6l12 12" />
</svg>
</button>
  return (
    <div className={className}>
    <CustomNavbar/>
      <div className="layout-inner-container">
        {children}
      </div>
    </div>
  );
};

export default Layout;
