import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import UserForm from "components/layout/user_form";
import Form from "./components/Form";
import TwoFactorForm from "./components/two-factor-auth";
import { Auth } from "aws-amplify";
import { notifyError } from "utils/helper";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.css";
import "styles/engage-tool.css";
import Loading from "components/Loading";
import logo from '../../assets/images/logo.webp'

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState("LOGIN");
  const [userData, setUserData] = useState({});
  const location = useLocation();
  const switchForm = (userCred) => {
    setUserData({ ...userData, ...userCred });
    setCurrentPage(
      currentPage === "LOGIN" ? "TWO_FACTOR_AUTH" : "TWO_FACTOR_AUTH"
    );
  };

  const onSubmit = async ({ username, password }) => {
    setLoading(true);
    //const USER_EXIST = await userExist(username, password)
    try {
      const user = await Auth.signIn({username, password});
      const mfaType = "CUSTOM_CHALLENGE";
      Auth.setPreferredMFA(user, mfaType);
      if (user.challengeName === mfaType) {
        // You need to get the code from the UI inputs
        switchForm({ username, password, user, code: userData.code, mfaType });
      } else {
        notifyError("User is not assigned any factor authentication.");
      }
      // else{
      //   try {
      //     const response = await Auth.signIn(username, password);
      //     const setUserSession = await Auth.currentSession();

      //     const role = setUserSession.getIdToken().payload.role
      //     const aff_pk = setUserSession.getIdToken().payload.aff_pk
      //     console.log(aff_pk, '<<');
      //     dispatch(
      //       await authActions.loginSuccess({
      //         isLoggedIn: true,
      //         email: response?.attributes?.email,
      //         name: response?.attributes?.name,
      //         role: role,
      //         aff_pk: aff_pk,

      //       })
      //     );

      //     notifySuccess(SUCCESS_MESSAGE);
      //     setLoading(false);
      //   } catch (err) {
      //     console.log(err, '<<,,');
      //     notifyError(err.message);
      //     setLoading(false); console.log(err)
      //   } finally {
      //     setLoading(false);
      //   }
      // }
    } catch (err) {
      console.log("🚀 ===== file: index.js ===== line 67 ===== err", err);
      notifyError(err.message);
      if (err.code === "UserNotConfirmedException") {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === "PasswordResetRequiredException") {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.code === "NotAuthorizedException") {
        // The error happens when the incorrect password is provided
      } else if (err.code === "UserNotFoundException") {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      } else {
        console.log(err);
      }
    }
    setLoading(false);
  };

  const onAuthCodeChange = (e) =>
    setUserData({ ...userData, code: e.target.value });

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const role = useSelector((state) => state.auth.role);
  if (isLoggedIn && role === "user") {
    return <Navigate to="/dashboard" state={{ from: location }} />;
  } else if (isLoggedIn && role === "affiliate_admin") {
    return <Navigate to="/dashboard" state={{ from: location }} />;
  } else if (isLoggedIn && role === "system_admin") {
    return <Navigate to="/dashboard" state={{ from: location }} />;
  }
  if (isLoggedIn && role ==='admin') {
    return <Navigate to="/admin" state={{ from: location }} />;
  }
  if (isLoggedIn && role ==='system_admin') {
    return <Navigate to="/user-request" state={{ from: location }} />;
  }

  return (
    <UserForm>
      <Loading isVisible={loading} />
      <ToastContainer/>
      {currentPage === "LOGIN" ? (
        <Form switchForm={switchForm} userData={userData} onSubmit={onSubmit}/>
      ) : (
        <TwoFactorForm
          userData={userData}
          onAuthCodeChange={onAuthCodeChange}
          onResend={onSubmit}
        />
      )}
    </UserForm>
  );
}
