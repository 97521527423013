import { userDeleteTypes } from "utils/constants";

const initialState = {
  listUserDelete: [],
};

export const userDeleteReducer = (state = initialState, action) => {
  switch (action.type) {
    case userDeleteTypes.ADD_USER_DELETE:
      const addData = state.listUserDelete;
      return {
        ...state,
        listUserDelete: [...addData, action.payload],
      };

    case userDeleteTypes.REMOVE_USER_DELETE:
      const removeData = state.listUserDelete.filter((item) => {
        return item.email !== action.payload.email;
      });

      return {
        ...state,
        listUserDelete: removeData,
      };

    case userDeleteTypes.CLEAR_USER_DELETE:
      return { ...state, listUserDelete: [] };

    default:
      return state;
  }
};
