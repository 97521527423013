import {
  ChangePassIcon,
  DeleteIcon,
  DownIcon,
  IconPower,
  UpdateIcon,
  UpIcon,
} from "assets/icons/Icons";
import React from "react";
import useCollapse from "react-collapsed";
import { useSelector } from "react-redux";
import { updateUserInfo } from "services/userUpdate";
import { notifyError, notifySuccess } from "utils/helper";
import { Tooltip } from "@mui/material";
import group  from "assets/images/groupIcon.png";

function UserItem({
  userItem,
  changePassword,
  updateUserRole,
  updateFieldValue,
  deleteUser,
  isDeleteUsers,
  handleChangeAffiliate,
}) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const handleActivateUser = async () => {
    try {
      await updateUserInfo({
        resume: [
          {
            email: userItem?.email,
          },
        ],
      });

      notifySuccess("Successfully");
    } catch (error) {
      console.log(error);
      notifyError(error.response.data.error.message);
    }
  };

  const authInfo = useSelector((state) => state.auth);

  return (
    <div key={userItem.timestamp} className="uLbox p-4">
      <div className="flex flex-wrap justify-between">
        {/* <div id="collapseFunction">{isExpanded ? "Collapse" : "Expand"}</div> */}

        <div className="w-1/2 md:w-28 userInfoRow">
          <div className="">
            <b>First Name</b>
          </div>
          <div>{userItem.first_name || "-"}</div>
        </div>
        <div className="w-1/2 md:w-28 userInfoRow">
          <div className="">
            <b>Last Name</b>
          </div>
          <div>{userItem.last_name || "-"}</div>
        </div>
        <div className="w-1/2 md:w-56 userInfoRow">
          <div className="">
            <b>Email</b>
          </div>
          <Tooltip title={userItem.email.length > 20 ? userItem.email : ""}>
            <div>{userItem.email.length > 20 ? userItem.email.slice(0, 20) + "..." : userItem.email}</div>
          </Tooltip>
        </div>
        <div className="w-1/2 md:w-32 userInfoRow">
          <div className="">
            <b>Mobile</b>
          </div>
          <div>
            {userItem.phone_check ? userItem.phone || "-" : "Not Available"}
          </div>
        </div>

        <div className="w-1/2 md:w-28 userInfoRow">
          <div className="">
            <b>Affiliate:</b>
          </div>
          <div>{userItem.affiliate_assoc || "N/A"}</div>
        </div>

        <div className="w-1/2 md:w-28 userInfoRow">
          <div className="">
            <b>Role</b>
          </div>
          <div>{userItem.role || "-"}</div>
        </div>
        <div className="w-1/2 md:w-24 userInfoRow">
          <div className="">
            <b>State</b>
          </div>
          <div>{userItem.state || "-"}</div>
        </div>
        <div className="w-1/2 md:w-20 userInfoRow">
          <div className="">
            <b>Status</b>
          </div>
          <div
            className={
              userItem.user_status === "active"
                ? "actStat"
                : userItem.user_status === "delete"
                ? "delStat"
                : ""
            }
          >
            {userItem.user_status === "delete"
              ? "Inactive"
              : userItem.user_status === "active"
              ? "active"
              : "-"}
          </div>
        </div>

        <div
          className="w-1/2 md:w-8 userInfoRow md:-translate-y-3"
          {...getToggleProps()}
        >
          {isExpanded ? <UpIcon /> : <DownIcon />}
        </div>
      </div>

      <div className="" {...getCollapseProps()}>
        {userItem?.user_status === "delete" ? (
          <div className="flex justify-center">
            <button
              className="userOpt bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md"
              onClick={handleActivateUser}
            >
              <div className="float-left mr-2">
                <IconPower size={22} />
              </div>
              Activate User
            </button>
          </div>
        ) : (
          <div>
            <div className="flex justify-center">
              <button
                className="userOpt bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md text-sm"
                onClick={() => changePassword(userItem.email)}
              >
                <ChangePassIcon className="float-left" />
                <span className="ml-2">Reset Password</span>
              </button>
              <button
                className="userOpt bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md text-sm"
                onClick={() => {
                  updateUserRole(userItem.email);
                  updateFieldValue(userItem.role);
                }}
              >
                <UpdateIcon className="float-left" />
                <span className="ml-2">Update Role</span>
              </button>
              <button
                className="userOpt bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
                onClick={() => deleteUser(userItem.email)}
              >
                <DeleteIcon className="float-left" />
                <span className="ml-2">Deactivate</span>
              </button>

              {authInfo?.role === "system_admin" && (
                <button
                  className="userOpt bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded-md text-sm"
                  onClick={() => handleChangeAffiliate(userItem)}
                >
                  <img src={group} className="float-left" width={"23.5"} alt=""/>
                  <span className="ml-2">Modify Affiliate Group</span>
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserItem;
