import axios from "services/setupAxios";
import { USER_LIST } from "utils/request";

export const requestUserList = ({ params }) => {
  return axios.get(USER_LIST, { params });
};

export const requestFilterUserList = ({ params }) => {
  return axios.get(USER_LIST, { params });
};
