import React, {createContext, useContext, useState, useCallback, useEffect} from 'react'
import {getToken} from '../../utils/getToken'
import {BEST_PRACTICE_CATEGORY, BEST_PRACTICE_CATEGORY_DETAIL, TOOL_CHEST_LINK_LIST} from '../../utils/request'

//Fetch all the categories and their articles from the database. Set initial state with the data

//Define methods to add categories, add articles, delete articles, update articles, and search for articles

/**
 * Interfaces:
 */
interface Category{
    uuid: string,
    created_at: string,
    aff_pk: string
    description: string,
    title: string,
    created_by: string
}


/**
 * Set up the context
 */

const CategoryMgmtContext = createContext<{} | undefined>(undefined);

export const useCategoryManagmentContext = () => {
    const context = useContext(CategoryMgmtContext);

    if(!context){
        throw new Error("useCategoryManagementContext must be used within a CategoryMgmtProvider")
    }

    return context;
};

const CategoryMgmtProvider = ({children}): JSX.Element => {
    const [categories, setCategories] = useState<Category[]>([])
    const [categoriesHasFetched, setCategoriesHasFetched] = useState(false);
    const [categoriesLoading, setCategoriesLoading] = useState(false);
    const [articlesLoading, setArticlesLoading] = useState(false);
    const [toolLinks, setToolLinks] = useState([]);
    const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);

    //instantiate context with category and article data:
    const fetchCategories = useCallback(async(apiURL: string, setStateVar: Function, setFetchState: Function) => {

        const token = await getToken();

        try{
            setCategoriesLoading(true);
            const response = await fetch(apiURL, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
                
            });

            const payload = await response.json();

            if(payload){
                setCategoriesLoading(false);
                setStateVar(payload.data);
                setFetchState(true)
            }
        } catch (err) {
            throw new Error(`Call to ${apiURL} failed: ${err}`)
        } finally {
            setCategoriesLoading(false);
        }

    }, []);

    useEffect(() => {
        if(!categoriesHasFetched){
            fetchCategories(BEST_PRACTICE_CATEGORY, setCategories, setCategoriesHasFetched)
        }
    }, [fetchCategories, categoriesHasFetched, categories]);


    // used to track category id selected from category list for fetching articles:
    const [categoryID, setCategoryID] = useState(undefined);

    const [articles, setArticles] = useState([]);

    const fetchArticles = useCallback(async() => {
        if(categoryID === undefined) return;
    
        const token = await getToken();

        try{
            setArticlesLoading(true);
            setArticles([]);
            const response = await fetch(`${BEST_PRACTICE_CATEGORY_DETAIL}?category_id=${categoryID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            });

            const payload = await response.json();

            console.log(`Response to article fetch: ${JSON.stringify(payload)}`);

            if(payload){
                setArticles(payload.data);
            }
        } catch(err){
            console.error(`Couldn't fetch articles due to: ${err}`)
        } finally {
            setArticlesLoading(false);
        }
    },[categoryID]);
    
    useEffect(() => {
        if(categoryID !== undefined || formSubmitSuccess === true){
            fetchArticles();
            
            setFormSubmitSuccess(false);
        }
    
    }, [categoryID, fetchArticles, formSubmitSuccess])

    //fetch tool chest links:
    const fetchToolLinks = useCallback(async() => {
        const token = await getToken();

        try{
            const response = await fetch(TOOL_CHEST_LINK_LIST, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": token, 
                }
            })
            
            const data = await response.json();
            if(data){
                setToolLinks(data);
            }

        } catch (error){
            throw new Error(`Could not retrieve chest list: ${error}`)
        } 
    }, []);

    useEffect(() => {
        fetchToolLinks();
    }, [fetchToolLinks]);


    return(
        <CategoryMgmtContext.Provider value={{categories, categoryID, setCategoryID, articles, categoriesLoading, articlesLoading, setArticlesLoading, toolLinks, setFormSubmitSuccess}}>
            {children}
        </CategoryMgmtContext.Provider>
    )
}

export default CategoryMgmtProvider