import "./App.css";
import Login from "./pages/login";
import { Route, Routes } from "react-router-dom";
import Register from "./pages/register";
import { Amplify} from "aws-amplify";
import ProtectedApp from "pages/ProtectedApp";
import UpdatePassword from "pages/update-password";
import Dashboard from "pages/dashboard";
import LandingPage from "pages/landing-page";
import CreateUser from "pages/create-user";
import Message from "pages/message";
import UserRequest from "components/userRequest";
import ToolChest from "pages/tool-chest";
import BestPractice from "pages/best-practice";
import MyProfile from "pages/my-profile";
import User from "pages/user";
import ErrorPage from "pages/404";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "pages/forgot-password";
import { useSelector } from "react-redux";
import ViewUser from "pages/view-users";
import "styles/custom.css";
import Layout from "components/layout";
import NEO from "pages/categories";
import Article from "pages/categories/Article";
import Category from "pages/best-practice/category";
import AddArticle from "pages/best-practice/add-article";
import AddCategory from "pages/best-practice/add-category";
import AddEvents from "pages/landing-page/AddEvents";
import MyStuff from "pages/my-profile/myStuff";
import EditArticle from "pages/best-practice/edit-article";
import ChestItemsProvider from "./components/ChestList/context.tsx";
import CategoryMgmtProvider from "./components/CategoryMgmt/context.tsx"

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
    authenticationFlowType: "CUSTOM_AUTH",
  },
});

function App() {
  const authInfo = useSelector((state) => state.auth);

  return (
    <Routes>
      <Route path="*" element={<ErrorPage />} />
      <Route path="/" element={<Login />} />
      {authInfo.role && (
        <Route
          path="/login"
          element={
            <ProtectedApp>
              <LandingPage />
            </ProtectedApp>
          }
        />
      )}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/update_password" element={<UpdatePassword />} />
      <Route path="/forgot_password" element={<ForgotPassword />} />
      {authInfo.role === "affiliate_admin" && (
         <>
          <Route
            path="/admin/view-users"
            element={
              <ProtectedApp>
                <Layout>
                  <ViewUser />
                </Layout>
              </ProtectedApp>
            }
          />
          <Route
            path="/affiliate-admin/create-user"
            element={
              <ProtectedApp>
                <CreateUser />
              </ProtectedApp>
            }
          />
        </>
      )}
      {authInfo.role === "system_admin" && (
        <>
          <Route
            path="/admin"
            element={
              <ProtectedApp>
                <Dashboard />
              </ProtectedApp>
            }
          />

          <Route
            path="/admin/view-users"
            element={
              <ProtectedApp>
                <Layout>
                  <ViewUser />
                </Layout>
              </ProtectedApp>
            }
          />

          <Route
            path="/admin/user-request"
            element={
              <ProtectedApp>
                <Layout>
                  <UserRequest />
                </Layout>
              </ProtectedApp>
            }
          />

          <Route
            path="/admin/create-user"
            element={
              <ProtectedApp>
                <CreateUser />
              </ProtectedApp>
            }
          />
        </>
      )}
      <Route
        path="/user"
        element={
          <ProtectedApp>
            <User />
          </ProtectedApp>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedApp>
            <LandingPage />
          </ProtectedApp>
        }
      />
      <Route
        path="/tool-chest"
        element={
          <ProtectedApp>
            <ChestItemsProvider>
              <ToolChest/>
            </ChestItemsProvider>
          </ProtectedApp>
        }
      />
      <Route
        path="/affiliate-admin"
        element={
          <ProtectedApp>
            <Dashboard />
          </ProtectedApp>
        }
      />

      <Route
        path="/affiliate-admin/user-request"
        element={
          <ProtectedApp>
            <Layout>
              <UserRequest />
            </Layout>
          </ProtectedApp>
        }
      />

      <Route
        path="/best-practice"
        element={
          <ProtectedApp>
            <CategoryMgmtProvider>
              <BestPractice />
            </CategoryMgmtProvider>
          </ProtectedApp>
        }
      />
      <Route
        path="/best-practice/:id"
        element={
          <ProtectedApp>
            <Category />
          </ProtectedApp>
        }
      />
      <Route
        path="/best-practice/add-category"
        element={
          <ProtectedApp>
            <AddCategory />
          </ProtectedApp>
        }
      />
      <Route
        path="/best-practice/add-article"
        element={
          <ProtectedApp>
            <AddArticle />
          </ProtectedApp>
        }
      />

      <Route
        path="/best-practice/edit-article"
        element={
          <ProtectedApp>
            <EditArticle />
          </ProtectedApp>
        }
      />

      <Route
        path="/categories"
        element={
          <ProtectedApp>
            <NEO />
          </ProtectedApp>
        }
      />
      <Route
        path="/categories/Article"
        element={
          <ProtectedApp>
            <Article />
          </ProtectedApp>
        }
      />
      <Route
        path="/landing-page/AddEvents"
        element={
          <ProtectedApp>
            <AddEvents />
          </ProtectedApp>
        }
      />

      <Route
        path="/my-profile"
        element={
          <ProtectedApp>
            <MyProfile />
          </ProtectedApp>
        }
      />

      <Route
        path="/my-stuff"
        element={
          <ProtectedApp>
            <MyStuff />
          </ProtectedApp>
        }
      />

      <Route
        path="/message"
        element={
          <ProtectedApp>
            <Message />
          </ProtectedApp>
        }
      />
    </Routes>
  );
}

export default App;
