import {
  ChevronUpIcon,
  DocumentIcon,
  IconMoreHorizontal,
} from "assets/icons/Icons";
import Loading from "components/Loading";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import bestPracticesActions from "redux/actions/bestPracticesAction";
import { requestAddArticle } from "services/bestpractice";
import { DATA_ROLES } from "utils/constants";

function CardCategory({ getFirstDetailCategory, data, isEditArticle }) {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const authInfo = useSelector((state) => state.auth);

  const onDeleteArticle = async () => {
    setLoading(true);

   
    try {
      await requestAddArticle({
        data: {
          delete: [{ uuid: data?.uuid }],
        },
      });
      getFirstDetailCategory();
    } catch (error) {
      console.log("🚀 ===== file: index.js ===== line 16 ===== error", error);
    }
    setLoading(false);

    
  };

  const onEditArticle = () => {
    dispatch(
      bestPracticesActions.addArticle({
        ...data,
      })
    );
    navigate(`/best-practice/edit-article`);
  };

  useEffect(() => {
    const tags =
      typeof data.tags === "string" ? data.tags.split("; ") : data.tags;
    setTags(tags);
  }, []);

  const toggleShowDetail = () => {
    setIsShowDetail(!isShowDetail);
  };

  /*Function to compare the current user and original author of post. If rank of current user is the same or higher than the original post, return true.

    args:

      curr_role: role of currently logged in user (default: authInfo.role)
      orig_role: role of best practice post author (default: data.created_by_role)
      roles: object holding role values and their rank (default: DATA_ROLES)
  */
  const role_comparison = (curr_role, orig_role, roles) => {
    let curr_role_rank = 0;
    let orig_role_rank = 0;

    roles.forEach(role => {

        if(role.value === curr_role) {
            
            curr_role_rank = role.rank 
        }

        if (role.value === orig_role) {

            orig_role_rank = role.rank
        }
    })

    if(curr_role_rank >= orig_role_rank){
        return true;
    } else {
        return false;
    }
}


  
  return (
    <div className="bg-white text-black-10 rounded-md shadow-md">
      <Loading isVisible={loading} />
    
      
      <div
        className={`flex items-center p-4 shadow-md ${
          isShowDetail && "border-b-8"
        }`}
        onClick={toggleShowDetail}
      >
        <div className="flex-1">
          <p className="font-bold m-0 p-0">{data?.title} </p>
          <p className="m-0 p-0">{data?.description}</p>
        </div>
        {isShowDetail ? (
          <div
            className="float-right hover:opacity-80 hover:scale-125 cursor-pointer"
            onClick={toggleShowDetail}
          >
            <ChevronUpIcon />
          </div>
        ) : (
          <div className="cursor-pointer hover:opacity-80 hover:scale-125">
            <IconMoreHorizontal size={32} />
          </div>
        )}
      </div>

      {isShowDetail && (
        <div className="bg-white flex-1 space-y-4 p-4 rounded-md h-fit">
          {/* icon up */}

          {/* Title */}
          <h1 className="px-3 border-b">{data?.title}</h1>

          {/* display body content */}
          <div
            className="bg-white text-black-10 p-4 rounded-md h-[600px] overflow-auto border-b"
            dangerouslySetInnerHTML={{ __html: data?.body }}
          />

          {/* display tool_chest_link */}
          {data?.tool_link_id && (
            <div className="border-b pb-4">
              <a
                href={data?.tool_link_id?.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data?.tool_link_id?.url}
              </a>
            </div>
          )}

          <div className="flex justify-between items-center gap-4 flex-wrap pb-3 border-b">
            {/* supplemental material */}
            <div className="flex gap-2">
              {data?.supplemental_material_urls?.map((url) => {
                console.log(`URLs being processed: ${url}`)
                return (
                  <div
                    key={url}
                    className="bg-green-10 w-fit rounded-full group relative"
                  >
                    <a
                      href={url}
                      className=" text-white cursor-pointer"
                      download={true}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="p-3">
                        <DocumentIcon />
                      </div>
                    </a>
                    <p className="group-hover:block hidden absolute m-0 text-white -top-10 w-fit bg-green-10 z-50 whitespace-nowrap p-1 rounded-md">
                      {decodeURIComponent(
                        url
                          //?.split("/")
                          //?.[url?.split("/")?.length - 1]?.split("?")[0]
                      )}
                    </p>
                    <div className="bg-green-10 p-2 absolute -top-5 right-1/2 left-1/2 rotate-45 hidden group-hover:block"></div>
                  </div>
                );
              })}
            </div>
            {/* tags */}
            <div className="flex text-white items-center">
              {tags?.map((tag, index) => (
                <div className="mr-2 bg-green-10 p-3 rounded-md" key={index}>
                  {tag}
                </div>
              ))}
            </div>
          </div>


          <div className="flex gap-4 text-white justify-between">
            {console.log(data)}
            <p className="flex" style={{ color: "gray" }}>
              Created By:{" "}
              {data.first_name + " " + data.last_name}
            </p>
            
            {(authInfo?.role !== "viewer" && role_comparison(authInfo.role, data.created_by_role, DATA_ROLES)) && (

              <div className="flex gap-4 justify-end">
                <button
                  className="px-3 rounded-md py-2 bg-red-600"
                  onClick={onDeleteArticle}
                >
                  Delete
                </button>

                <button
                  type="submit"
                  className="px-3 rounded-md py-2 bg-blue-600"
                  onClick={onEditArticle}
                >
                  Edit
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default CardCategory;
