
import { Link } from 'react-router-dom';
import './BreadCrumbs.css';

/**
 * @typedef {Object} menuItem
 * @property {string} menuName - Name of breadcrumb item.
 * @property {string} menuLink - Link of breadcrumb item.
 */

/**
 * A React component to render breadcrumbs.
 * @param {Object} props - The component props.
 * @param {menuItem[]} props.menuItems - An array of menu items.
 * @returns {JSX.Element} The breadcrumbs component.
 * 
 * @example
 * // Usage:
 * const menuItems = [
 *   { menuName: 'Home', menuLink: '/' },
 *   { menuName: 'About', menuLink: '/about' },
 *   { menuName: 'Contact', menuLink: '/contact' }
 * ];
 * 
 * <BreadCrumbs menuItems={menuItems} />
 */
export const BreadCrumbs = ({ menuItems }) => {
    return(
        <ul className="breadcrumb-list">
            {menuItems.map((item) => 
                <li key={item.menuName} className="breadcrumb-item">
                    <Link to={item.menuLink}className="breadcrumb-link">
                        {item.menuName}
                    </Link>
                </li>
            )}
        </ul>
    
    );
};