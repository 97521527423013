import * as yup from "yup";
import { REQUIRED_EMAIL, REQUIRED_FIELD } from "utils/constants";

export const schemaSignUp = yup
  .object({
    // validate first name
    firstName: yup.string().required(REQUIRED_FIELD),
    // validate last name
    lastName: yup.string().required(REQUIRED_FIELD),
    // validate email
    email: yup.string().email(REQUIRED_EMAIL).required(REQUIRED_FIELD),
    // validate phone
    phone: yup.string(),
  })
  .required();

export const schemaSignIn = yup
  .object({
    // validate userName
    username: yup
      .string()
      .email("Email must be a valid email")
      .required("Please enter the required field"),
    // validate password
    password: yup.string().required("Please enter the required field"),
  })
  .required();

export const schemaNewPassword = yup.object({
  newPassword: yup.string().required(REQUIRED_FIELD),
  newPasswordVerify: yup.string().required(REQUIRED_FIELD),
});

