import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "../reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "aff_pk", "bestPractices", "previousPage"],
};

const configurateStore = (preloadedState) => {
  const middlewares = [thunkMiddleware];

  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer];
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const composedEnhancers = composeWithDevTools(...enhancers);
  const store = createStore(
    persistedReducer,
    preloadedState,
    composedEnhancers
  );
  const persistor = persistStore(store);

  return { store, persistor };
};
export const { store, persistor } = configurateStore();
