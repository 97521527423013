import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "styles/engage-tool.css";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { UserPageContainer } from "components/layout/UserPageContainer/UserPageContainer";
import { MainContentContainer } from "components/layout/MainContentContainer/MainContentContainer";
import {EventModal} from '../../components/EventModal/EventModal'
import AddChestItem from "components/ChestList/AddChestItem/AddChestItem";
import {notifySuccess} from 'utils/helper'
import { useChestItemContext } from "../../components/ChestList/context.tsx";
import  {ChestListItem}  from "../../components/ChestList/ChestListItem/ChestListItem";
import './tool-chest-styles.css';
import LoadingCard from "components/loading-card";

export default function ToolChest() {

  const cancelDeleteItemHandler = () => {
    setDropItem(undefined);
    setIsModalOpen(false);
  }

  const confirmToolChestDelete = () => {
    const deleteContainerStyle = {
      color: 'white',
    }

    const deleteBtnGroupStyle = {
      display: 'flex',
      justifyContent: 'end'
    }

   
    return(
      <div style={deleteContainerStyle}>
        <p>Are you sure you want to delete this tool chest item?</p>
        <div style={deleteBtnGroupStyle}>
          <button style={{padding: '0.5em 1em', background: 'var(--primary)', borderRadius: '0.25em', marginRight: '1em'}} type="button" onClick={deleteToolChestHandler}>Yes</button>
          <button style={{padding: '0.5em 1em', background: 'var(--state-warning)', borderRadius: '0.25em'}} type="button" onClick={cancelDeleteItemHandler}>No</button>
        </div>
      </div>
    )
  } 

  const {items, addItem, deleteItem, loading} = useChestItemContext() //get the items and CRUD actions from page context

  const BreadCrumbsMenu = [
    {menuName: 'Calendar', menuLink: '/dashboard'},
    {menuName: 'Tool Chest', menuLink: '/tool-chest'},
    {menuName: 'Best Practices', menuLink: '/best-practice'}
  ]

  const [isModalOpen, setIsModalOpen] = useState(false);
  const authInfo = useSelector((state) => state.auth);
  const [dropItem, setDropItem] = useState(undefined);

  const closeModalHandler = () => {
    setIsModalOpen(false);
  }

  const dropItemHandler = (uuid) => {
    setDropItem(uuid);
    setIsModalOpen(true);
  }

  const deleteToolChestHandler = async() => {
    const itemBeingDropped = items.filter(item => item.uuid === dropItem)[0];
    const {uuid, role, affPk_timestamp} = itemBeingDropped;
    
    try{

      await deleteItem({uuid, role, affPk_timestamp});
      setIsModalOpen(false);
    } catch(error){
      console.error(`Error deleting chest item: ${error}`)
    } finally{
      setDropItem(undefined);
      setIsModalOpen(false);
    }
  }

  
  return (
    <UserPageContainer breadcrumbs={BreadCrumbsMenu}>
      <EventModal isOpen={isModalOpen} onClose={closeModalHandler}>
        {dropItem ? confirmToolChestDelete() : <AddChestItem handleModalClose={closeModalHandler} addItem={addItem}/>}
      </EventModal>
      <MainContentContainer>
        <div className="tool-chest-container">
          <div className="tool-chest-add-btn-container">
                {authInfo.role !== 'viewer' ? (
                    <button className="tool-chest-add-btn" onClick={() => setIsModalOpen(true)}>Add Tool Chest Link</button>
                ) : null}
            </div>
          {loading ? (<LoadingCard />) :
            (
              <div className="tool-chest-items-container">
              {/**Note: currently showing nothing if no items */}
              {(items && items.length > 0) && items.map(item => {
                  return(
                      <ChestListItem key={item.uuid} item={item} dropItem={dropItemHandler}/>
                  )
                })}
              </div>
            )
          }
        </div>
      </MainContentContainer>
    </UserPageContainer>
  )
}


