import './ArticleContainer.css'


export const ArticleContainer = ({article, viewArticle, setViewArticle, setCurrentArticle}) => {

    const {title, updated_at, uuid} = article
    
    //Is the article new?
    const current_date = Date.parse(new Date());
    const article_date = Date.parse(updated_at);

    const elapsed_time = current_date - article_date;
    const twoWeeksInMilliseconds = 2 * 7 * 24 * 60 * 60 * 1000;

    const setViewHandler = (uuid) => {
        setViewArticle(!viewArticle);
        setCurrentArticle(uuid)
    }


    return(
        <>
            <li onClick={() => setViewHandler(uuid)}><button className="list-item">{title}</button>{elapsed_time < twoWeeksInMilliseconds && <span>New</span>}</li>
        </>
    )
}