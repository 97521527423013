import { LoadingIcon } from "assets/icons/Icons";
import React from "react";

export const DynamicButton = ({label, color, disabled, loading, rounded, outlined, icon, tabIndex}) => {
  return (
    <button
      tabIndex={tabIndex}
      className="button">
      {label}
    </button>
  )
}

export const Button = ({ text, color, tabIndex }) => {
  return (
    <button tabIndex={tabIndex}
      className={`${color} py-1 sm:py-1.5 px-4 text-white font-bold rounded-full font-medium min-w-[160px] min-h-[50px] hover:opacity-80 shadow-lg duration-500 truncate`}
    >
      {text}
    </button>
  );
};

export const SmallButton = ({ text, color, tabIndex }) => {
  return (
    <button tabIndex={tabIndex}
      className={`${color} py-2 px-3 text-white font-bold rounded-full text-sm min-w-[90px] hover:opacity-80 shadow-lg duration-500 truncate`}
    >
      {text}
    </button>
  );
};

export const DisableBtn = ({ text }) => {
  return (
    <button className="bg-slate-400 py-1 sm:py-1.5 px-4 text-white rounded-full text-sm min-w-[160px] shadow-lg cursor-default">
      {text}
    </button>
  );
};

export const GreenButton = ({ text, tabIndex, className }) => {
  return (

    <button tabIndex={tabIndex} className={`btn btn-green ${className}`}>
      <b>{text}</b>
    </button>
  );
};

export const RedButton = ({ text, tabIndex, className }) => {
  return (

    <button tabIndex={tabIndex} className={`btn btn-red ${className}`}>
      <b>{text}</b>
    </button>
  );
};

export const DisableButton = ({ text, className }) => {
  return (
    <button
      type="button"
      className={`btn btn-disabled ${className}`}
      disabled
    >
      <p className="p-0 m-0">{text}</p>
      <LoadingIcon />
    </button>
  );
};

export const ClearButton = () => {
  return (
    <div className="bg-red-10 mb-2 text-white w-[160px] px-4 py-1 rounded-full font-medium hover:opacity-80 cursor-pointer">
      Clear
    </div>
  );
};
