
import { Auth } from "@aws-amplify/auth/lib-esm/Auth";
import Layout from "components/layout";
import React, { useState, useEffect } from "react";
import { requestArticles } from "services/bestpractice";
import { requestGetCalendarEvent } from "services/calendar";
//import { useParams } from "react-router-dom";
import { requestCategory } from "services/bestpractice";
import CardCategory from "components/category";

export default function MyStuff() {
    const [calendarItem, setcalendarItem] = useState([]);
    const [categoryItem, setcategoryItem] = useState([]);
    const [user, setuser] = useState([]);
    const [allArticles, setallArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  //let params = useParams();


/*   useEffect(async () => {
    await fetchList();
    
  }, []); */
  const currentDay = new Date();
  let month = currentDay.getMonth() + 1;
  let year = currentDay.getFullYear();

  const fetchList = async () => {
    setLoading(true);
    try {
      const response = await requestGetCalendarEvent({
        params: { month: month, year: year },
      });
    //const response = await requestGetCalendarEvent();
    console.log(response);
    if (response.status === 200) {
      setcalendarItem(response.data.data);
    } }catch (error) {
      console.log("error", { ...error });
    }
    

    const response2 = await Auth.currentAuthenticatedUser();
    setuser(response2.username);

    
    const response3 = await requestArticles();
    if (response3.status === 200) {
      setallArticles(response3.data.data);
    }

    const response4 = await requestCategory();
    if (response4.status === 200) {
      setcategoryItem(response4.data.data);
    }


    setLoading(false);
    
  };

  var count = 0, count2 = 0;

  useEffect(() => {
    fetchList();
  }, []);
    return(
        <Layout>
            <navbar id="nav2">
                <a href="/my-profile" className="">
                    My Info
                </a>
                &nbsp;/&nbsp;
                <a href="/my-stuff" className="active">
                    <b>My Stuff</b>
                </a>
            </navbar>
            <div className="container-fluid listBox myP">
                <div className="row">
                    <div className="col-md-12 flex justify-content-center">
                        <h2 className="linkTitle"><b>My Calendar Events</b></h2>
                    </div>
                </div>
                <div className="row">
                  <div className="col-md-12 flex justify-content-center eventCardCenter">
                        {calendarItem && calendarItem.map((el, index) => {
                          console.log(el);
                          if (user === el.created_by){
                            count = 1;
                            return(
                            <div className="eventCard">
                              <h5 className="eventCardTitle">{el.title}</h5>
                              <p className="eventCardDate">{new Date(el.start_date).toLocaleString(`en-US`) + "\n" + new Date(el.end_date).toLocaleString(`en-US`)}</p>
                              {el.description === "" ?
                                (<p className="eventCardDescription-blank">No Description</p>)
                                :
                                (<p className="eventCardDescription">{el.description}</p>)
                              }
                            </div>
                            )}
                        })}
                        {count === 0 ? <h2 className="eventCardDescription-blank">No Calendar Events</h2> : ""}
                  </div>
                </div>
              </div>
                <div className="container-fluid listBox myP">
                <div className="row py-4">
                    <div className="col-md-12 flex justify-content-center" >
                      <h2 className="linkTitle"><b>My Articles</b></h2>
                    </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <ul className="articleSection">
                    {allArticles && allArticles.map((el2, index) => {
                      if (user === el2.created_by.cognito_id){
                        count2 = 1;
                        return(
                          <div className="articleCard">
                            <CardCategory data={el2}>
                              <p>{el2.title}</p>
                            </CardCategory>
                          </div>)}
                    })}
                    </ul>
                    {count2 === 0 ? <h2 className="eventCardDescription-blank flex justify-content-center">No Articles</h2> : ""} 
                  </div>
                </div>
            </div>
        </Layout>
    );
}