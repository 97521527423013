import Layout from "components/layout";
import Documents from "assets/images/documents.png";
import React from "react";
import 'bootstrap/dist/css/bootstrap.css';

export default function Article() {
  return (
    <Layout title="Article">
      <navbar id="nav2">
        <a href='./dashboard'>Calendar</a>&nbsp;/&nbsp;
        <a href='./tool-chest'>Tool Chest</a>&nbsp;/&nbsp;
        <a href='./best-practice' className="active"><b>Best Practices</b></a>
      </navbar>
      <br/>
      <br/>
      <div className="container-fluid articleBox">
          <div className="row">
              <div className="col-md-12">
                  <h1>Welcome potential new members to their workplace!</h1>
                  <hr/>
              </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="article">
                <h3>Why this is important:</h3>
                <p>Starting at a new workplace is often an exciting moment for our potential new members and we should celebrate the moment with them! A welcome e-mail is an excellent way to introduce AFSCME to potential new members and let them know we are present in the workplace.</p>
                <h3>How you should reach out:</h3>
                <p>By e-mail. This will allow you to include links and attachments to additional material relevant to the potential new member. This e-mail should supplement one-on-one in-person interactions.</p>
                <h3>What to include in your outreach:</h3>
                <p>Your e-mail should be personal to the potential new member and ideally references their workplace. Many potential new members have never interact with AFSCME or any union before so provide a brief explanation of who AFSCME is and what it means to be a member of our union. Attaching or linking to the current collective bargaining agreement is an excellent way to show what our union has fought for and won in their workplace.</p>
                <p>Always include an ask or invitation for potential new members to get involved. If there is an upcoming membership meeting or union event scheduled, include the information for the event in the e-mail.</p>
                <p>Use the included e-mail template as a starting point for your introductory e-mail. Don’t forget to make it personal to your affiliate and locals!</p>
                <h3>When this best practice should happen:</h3>
                <p>Ideally within the first week of starting, but within first month of employment.</p>
                <hr/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12"><a href="https://afscme-my.sharepoint.com/:w:/g/personal/atill_afscme_org/Ee1i0BRzGDJLqCHhYYMRiy8BXAcAY43yvLruegYkfEsY8w?e=4%3ADxavsn&at=9&CID=6d5c2068-73a8-2c4a-3263-4468cdaa5b78https://afscme-my.sharepoint.com/:w:/g/personal/atill_afscme_org/Ee1i0BRzGDJLqCHhYYMRiy8BXAcAY43yvLruegYkfEsY8w?e=4%3ADxavsn&at=9&CID=6d5c2068-73a8-2c4a-3263-4468cdaa5b78" target="_blank" rel="noreferrer"><img src={Documents} width="50" alt="documents"/></a></div>
          </div>
      </div>
    </Layout>
  );
}