import React from "react";
import  "../styles/PopupHelp.css";

function PopupHelp({ setOpenModal }) {
    return (
    <div className="modalBackground">
        <div className="modalContainer">
        <div className="titleCloseBtn">
            <button className="titleCloseBtn"
            onClick={() => {
                setOpenModal(false);
            }}
            >
            X
            </button>
        </div>
        
        <div className="body">
                <embed  type="text/html" src="/docs/index.html" width="100%" height="100%" ></embed>
        </div>
        
        </div>
    </div>
    );
}

export default PopupHelp;
