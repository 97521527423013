import { Auth } from "@aws-amplify/auth/lib-esm/Auth";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";

function ProtectedApp({ children }) {
  const location = useLocation();
  const loggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [isLoggedIn, setIsLoggedIn] = useState(loggedIn);

  const currentSession = useCallback(async () => {
    try {
      const response = await Auth.currentAuthenticatedUser();
      if (response) {
        setIsLoggedIn(true);
      }
    } catch (error) {
      console.log("You are not logged in");
      setIsLoggedIn(false);
      
    }
  }, []);

  useEffect(() => {
    currentSession();
    return () => {};
  }, [currentSession]);

  if (isLoggedIn) {
    return children;
  } else {
    return <Navigate to="/login" state={{ from: location }} />;
  }
}

export default ProtectedApp;
