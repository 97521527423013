import {Portal} from '../Portal';
import './EventModal.css'

export const EventModal = ({isOpen, onClose, children}) => {
    if(!isOpen) return null;

    return(
        <Portal>
            <div className="modal-overlay">
                <div className="modal">
                    <button className="modal-close" onClick={onClose}>⛌</button>
                    {children}
                </div>
            </div>
        </Portal>
    )
}