import { bestPracticesTypes } from "utils/constants";

const initialState = {
  article: {},
};

export const bestPracticesReducer = (state = initialState, action) => {
  switch (action.type) {
    case bestPracticesTypes.ADD_ARTICLE:
      return {
        ...state,
        article: action.payload,
      };

    case bestPracticesTypes.DELETE_ARTICLE:
      return {
        ...state,
        article: [],
      };

    default:
      return state;
  }
};
