import React, { useEffect, useState, useRef, useCallback} from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import { requestAddCalendarEvent } from "services/calendar";
import Loading from "components/Loading";
import axios from "axios";
import { useSelector } from "react-redux";
import { fetchAffliatessAPI, fetchStateAPI } from "services/register";
import Select from "react-select";
import { notifyError, readFileArrayBuffer } from "utils/helper";
import { requestPresigned } from "services/bestpractice";
import { CloseIcon } from "assets/icons/Icons";

//schema to validate event inputs
const schema = yup
  .object({
    title: yup.string().required("This is a required field"),
    start: yup.date().required("Please specify the start time"),
  })
  .required();

// {addEventApi, error}

const AddEvents = ({ onClose, getCalendarEvent }) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const authInfo = useSelector((state) => state.auth);
  // console.log(authInfo);

  const [dataState, setDataState] = useState([]);
  const [selectState, setSelectState] = useState('');

  const [dataAffliates, setDataAffliates] = useState([]);
  const [selectAffliates, setSelectAffliates] = useState(null);

  const [listFileUpload, setListFileUpload] = useState([]);

  const [IUEvent, setIUEvent] = useState();
  const [allDayChecked, setAlldayChecked] = useState(false);


  const handleSelectState = (event) => {
    console.log("🚀 ===== event", event);
    setSelectState(event);
    setSelectAffliates(null);
  };

  useEffect(() => {
    setIUEvent(authInfo.aff_pk ? 'YES' : 'NO')
  }, [authInfo.aff_pk])


  const endDate = (date) => {
    const s = date ? new Date(date) : undefined;
    s?.setHours(23,59,59);
    
    return s;
  }
  const startDate = (date) => {
    const s = date? new Date(date) : undefined;
    s?.setHours(0,0,0);
  

    return s;
  }

  const handleSelectAffliates = (event) => {
    setSelectAffliates(event);
  };

  const getAff_pk = () => {
    const role = authInfo?.role;
    if (role === "affiliate_admin" || role === "user") return +authInfo?.aff_pk;

    if (role === "system_admin" && IUEvent === "YES") return 14429;

    if (role === "system_admin" && IUEvent === "NO")
      return +selectAffliates?.aff_pk;
  };

  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    }
  }, [])

  const onSubmit = async (values) => {
    setLoading(true);

    const endDate = values?.end;
    const startDate = values?.start;
    const aff_pk = getAff_pk();

    if (endDate > startDate) {
      try {
        const listURL = await Promise.all(
          listFileUpload.map(async (item) => {
            const responsePresigned = await requestPresigned({
              data: {
                object_key: item?.name,
                content_type: item?.type,
                action: "putObject",
              },
            });
            const signedUrl = responsePresigned.data;
            return signedUrl;
          })
        );
        const fileData = await Promise.all(
          listFileUpload.map(async(file) => {
            try {
              if(!file.blob || !(file.blob instanceof Blob)){
                throw new Error(`Invalid blob for file: ${file.name}`)
              }

              return await readFileArrayBuffer(file.blob)
            } catch(err){
              console.error(`Error occured on reading file buffer: ${file.name}`);
              return null;
            }
            
          })
        );

        await Promise.all(
          fileData.map(async (d, index) => {
            try{
              if(!d || !listURL[index]){
                throw new Error(`File data or URL is undefined for index: ${index}`)
              }

              await axios({
                method: "put",
                url: listURL[index],
                data: d,
                headers: { "Content-Type": listFileUpload[index]?.type },
              });

            } catch(err){
              console.error(`Error uploading file data to URL: ${listURL[index]}: ${err}`)
            }
          })
        );

        console.log(`Values: ${JSON.stringify(values)}`)
        // create event
        await requestAddCalendarEvent({
          data: {
            create: [
              {
                title: values?.title,
                description: values?.describe,
                start_date: values?.start,
                end_date: values?.end,
                upload_urls: listURL,
                aff_pk: aff_pk,
                allday_check: false,
                
              },
            ],
          },
        });
      } catch (error) {
        console.log("🚀 ===== file: index.js ===== line 54 ===== error", {
          ...error,
        });
      }
    } else {
      notifyError("End date/time should be greater than start date/time");
    }
    onClose();
    await getCalendarEvent();
    
    if(isMounted.current){
      setLoading(false);
    }
  };

  const getStates = useCallback(async () => {
    try {
      const response = await fetchStateAPI();

      if (!response?.data) return;
      const selectStateData = response?.data.map((item) => {
        return { value: item?.state, label: item.state };
      });

      setDataState(
        selectStateData.sort((a, b) => {
          if (a.value > b.value) {
            return 1;
          }
          if (b.value > a.value) {
            return -1;
          }
          return 0;
        })
      );
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error", error);
    }
  }, [setDataState]);

  const getAffiliates = useCallback(async () => {
    try {
      const response = await fetchAffliatessAPI({
        data: { state: selectState.value },
      });

      if (response && response.data) {
        if (response.data.length === 0) {
          setDataAffliates([]);
        } else {
          var filteredAffliatedData = response?.data;
          filteredAffliatedData = filteredAffliatedData.filter((x) => 
            x.type === "C" || x.type === "R" || x.type === "L" 
          );
          const selectAffliatesData = filteredAffliatedData
            .sort((a, b) => {
              if (a.short_name > b.short_name) {
                return 1;
              }
              if (b.short_name > a.short_name) {
                return -1;
              }
              return 0;
            })
            .map((item) => {
              return {
                ...item,
                value: item?.short_name,
                label: item?.short_name,
                affPk: item?.aff_pk,
                parent: item?.parent,
              };
            });

          setDataAffliates([
            //{ value: "all", label: "All", state: selectAffliatesData[0].state },
            ...selectAffliatesData,
          ]);
        }
      }
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error:", error);
    }
  }, [selectState.value, setDataAffliates]);

  // ref to input file type;
  const fileInputRef = useRef(null);

  const handleSelectFile = () => {
    if(fileInputRef.current && fileInputRef.current.files.length > 0){
      const file = fileInputRef.current.files[0];

      setListFileUpload(prevState => {
        const fileExists = prevState.some(f => 
          f.name === file.name && f.type === file.type
        );

        if(!fileExists){
          return [...prevState, {blob: file, name: file.name, type: file.type}];
        }

        return prevState
      });
    }
  };


  const removeFile = (file) => {
    /* const listFiles = [];

    listFileUpload.forEach((item) => {
      if (item?.name !== file?.name) {
        listFiles.push(item);
      }
    });

    setListFileUpload(listFiles);*/

    const updatedListFiles = listFileUpload.filter(item => item?.name !== file?.name);

    if(isMounted.current){
      setListFileUpload(updatedListFiles)
    }
  };

  const toggleIUEvent = (value) => {
    setIUEvent(value);
  };

  useEffect(() => {
    getStates();
  }, [authInfo, getStates]);

  useEffect(() => {
    if (selectState) {
      getAffiliates();
    }
  }, [selectState, getAffiliates]);



  return (
    //this form is in bootstrap
    <>
      <Loading isVisible={loading} className="loader"/>
          <form
            onSubmit={handleSubmit(onSubmit)}>
              <div className="form-field">
                <label htmlFor="title" className="form-label">
                  Event Title:
                </label>
                <input
                  {...register("title")}
                  type="text"
                  placeholder="Title"
                  className="form-control eventBox"
                  id="title"
                  aria-describedby="title"
                />
                {errors.title ? <p className="error-msg">{errors.title.message}</p> : ''}
              </div>

              <div className="form-field" id="allDayCheck">
                <input 
                  {...register("allDay")} 
                  type="checkbox" 
                  id="allDay"
                  onChange={(e) => {setAlldayChecked(e.target.checked); 

                    if (e.target.checked) {
                      setValue('start', startDate(getValues('start')));
                      setValue('end', endDate(getValues('end')));
                    }
                  }}
                />
                <label htmlFor="allDay" className="form-label">
                  All Day Event
                </label>
              </div>

              <div className="form-field">
                <label htmlFor="start" className="form-label">
                  Start Date:
                </label>
                {/* controllers are the way you can wrap and use datePicker inside react form-hook*/}
                {/* start date controller*/}
                <Controller
                  control={control}
                  name="start"
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="Select date"
                      onChange={(date) => {allDayChecked ? field.onChange(startDate(date)) : field.onChange(date);}}
                      selected={allDayChecked ? startDate(field.value) : field.value}
                      value={allDayChecked ? startDate(field.value) : field.value}
                      showTimeSelect={!allDayChecked}
                      timeFormat="HH:mm"
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className="form-control"
                      id="start"
                      autoComplete="off"
                    />
                  )}
                />
                {/* error handling */}
                {errors.start ? <p className="error-msg">{errors.start.message}</p> : ''}
              </div>

              <div className="form-field">
                <label htmlFor="end" className="form-label">
                  End Date:
                </label>
                {/* end date controller*/}
                <Controller
                  control={control}
                  name="end"
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="Select end date"
                      onChange={(date) => {  allDayChecked ? field.onChange(endDate(date)) :field.onChange(date);}}
                      selected={allDayChecked ? endDate(field.value) : field.value}
                      value={allDayChecked ? endDate(field.value) : field.value}
                      timeFormat="HH:mm"
                      dateFormat="MMMM d, yyyy h:mm aa"
                      showTimeSelect={!allDayChecked}
                      className="form-control"
                      id="end"
                      autoComplete="off"
                    />
                  )}
                />
              </div>

              <div className="form-field">
                <label htmlFor="describe" className="form-label">
                  Event Description: {" "}
                  <span>(optional)</span>
                </label>
                <input
                  {...register("describe")}
                  type="text"
                  placeholder="describe your event"
                  className="form-control"
                  id="describe"
                  aria-describedby="describe"
                />
              </div>

              <div className="form-field" id="iu-select">
              {authInfo.role === "system_admin" ? (
                <>
                  <label htmlFor="title" className="form-label">
                    IU Event?
                  </label>
                  <input
                    {...register("IUEvent")}
                    id="IUEventT"
                    className=""
                    type="radio"
                    checked={IUEvent === 'YES'}
                    value="YES"
                    onChange={() => toggleIUEvent("YES")}
                  />
                  <label htmlFor="IUEventT">
                    YES
                  </label>
                  <input
                    {...register("IUEvent")}
                    id="IUEventF"
                    className=""
                    type="radio"
                    checked={IUEvent === 'NO'}
                    value="NO"
                    onChange={() => toggleIUEvent("NO")}
                  />
                  <label htmlFor="IUEventF">
                    NO
                  </label>
                </>
              ) : (
                <></>
              )}
              </div>

              
              <div className="form-field">
              <Controller
                name="state"
                render={({ field }) => (
                  <Select
                    name="selectState"
                    className=""
                    {...field}
                    options={dataState}
                    onChange={handleSelectState}
                    value={
                      authInfo?.role === "affiliate_admin" ||
                      authInfo?.role === "user"
                        ? {
                            label: authInfo?.state,
                            value: authInfo?.state,
                          }
                        : selectState
                    }
                    placeholder="State"
                    tabIndex={7}
                    isDisabled={IUEvent === "YES"}
                  />
                )}
                control={control}
              />
            </div>

            <div className="form-field">
              <Controller
                name="affliates"
                render={({ field }) => (
                  <Select
                    className=""
                    {...field}
                    options={dataAffliates}
                    isDisabled={selectState.length === 0 || IUEvent === "YES"}
                    onChange={handleSelectAffliates}
                    value={
                      authInfo?.role === "affiliate_admin" ||
                      authInfo?.role === "user"
                        ? {
                            label: authInfo?.affiliate_assoc,
                            value: authInfo?.affiliate_assoc,
                          }
                        : selectAffliates
                    }
                    placeholder="Affiliate"
                    tabIndex={8}
                  />
                )}
                control={control}
              />
            </div>

            <div className="form-field" id="uploads">
                <input type="file" ref={fileInputRef} onChange={handleSelectFile} />
                  {listFileUpload.map((file) => {
                    return (
                      <div
                        key={file?.name} id="upload-item">
                          <button onClick={() => removeFile(file)}>
                          <CloseIcon size={24} />
                          </button>
                          <p>{file.name}</p>
                      </div>
                    );
                  })}
              </div>

              <button
                type="submit">
                Create
              </button>
          </form>
        </>

  );
};

export default AddEvents;
