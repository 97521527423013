import {useState, useLayoutEffect} from 'react'

const UserForm = ({children, menuBtn}) => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useLayoutEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        }
        
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    return(
        <div className="form-parent" style={{height: windowHeight ? windowHeight: '100vh',  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <div className="form-container">
                {children}
            </div>
        </div>
    )
}
export default UserForm