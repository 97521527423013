import axios from "axios";
import axiosInstance from "./setupAxios";
import { AFFILIATE_STATE_LIST, REQUEST_USER_ACCESS, REQUEST_USER_REGISTRATION, GET_AFFILIATE_INFO } from "utils/request";

export const getAffiliateInfo = ({params})=>{
  return axiosInstance.get(GET_AFFILIATE_INFO, {params});
}

export const fetchStateAPI = () => {
  return axios.post(AFFILIATE_STATE_LIST);
};

export const fetchAffliatessAPI = ({ data }) => {
  return axios.post(AFFILIATE_STATE_LIST, data);
};

export const fetchSelectSublocalAPI = ({ data }) => {
  return axios.post(AFFILIATE_STATE_LIST, data);
};

export const reigsterUserData = ({ data }) => {
  return axios.post(REQUEST_USER_ACCESS, data);
};

export const reigsterNewUser = ({ data }) => {
  return axiosInstance.post(REQUEST_USER_REGISTRATION, data);
}
