import './BestPracticeForm.css';
import {useForm} from "react-hook-form";
import Editor from "../../../components/editor/index"
import {useState, useRef} from 'react';
import {PRESIGNED, MANAGE_BEST_PRACTICES} from '../../../utils/request';
import {getToken} from '../../../utils/getToken'
import { readFileArrayBuffer } from '../../../utils/helper';
import {LoadingState} from '../../../components/LoadingState'

//article as prop for existing props:


export const BestPracticeForm = ({article = {}, category, newArticle, toolLinks, setFormSubmitSuccess}) => {

    const {title, description, body, supplemental_material_urls = [], tags = [], tool_link_id, first_name, last_name, created_at, updated_at, uuid} = article;

    console.log(`Supplemental materials: ${supplemental_material_urls}`)

    const link_meta = toolLinks.map(link => ({
        uuid: link.uuid,
        url: link.url
    }));

    const [listFileUpload, setListFileUpload] = useState([]);

    const initialEditorState = newArticle ? false : true;
    const [editorReadOnly, setEditorReadOnly] = useState(initialEditorState);
    const [articleTag, setArticleTag] = useState('');
    const [articleTags, setArticleTags] = useState(tags);
    const [toolChestLinkID, setToolChestLinkID] = useState(tool_link_id);
    const [editorContent, setEditorContent] = useState(body || '');
    

    const {register, handleSubmit, setValue, formState: {errors}} = useForm({
        defaultValues: {
            category_id: category,
            title: title,
            description: description,
            body: body, 
            tags: tags,
            tool_link_id: tool_link_id,
            supplemental_material_urls: supplemental_material_urls
        }
    });

    const onSubmit = async(data) => {
        data.tags = articleTags
        data.tool_link_id = toolChestLinkID;
        data.body = editorContent;
        
    
        try{
            const token = await getToken()
            if(listFileUpload.length > 0){
                const listURL = await Promise.all(
                    listFileUpload.map(async (item) => {
                        const responsePresigned = await fetch(PRESIGNED, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': token
                            },
                            body: JSON.stringify({
                                object_key: item.name,
                                content_type: item.type,
                                action: 'putObject'
                            })
                        });

                        const signedUrl = await responsePresigned.json()
                        return signedUrl ;
                    })
                );

                const fileData = await Promise.all(
                    listFileUpload.map(async(file) => {
                        if(!file.blob || !(file.blob instanceof Blob)){
                            throw new Error(`Invalid blob for file: ${file.name}`)
                        }

                        return await readFileArrayBuffer(file.blob);
                    }) 
                );

                await Promise.all(
                    fileData.map(async(dataBuffer, index) => {

                        if(!dataBuffer || !listURL[index]){
                            throw new Error(`File data or URL is undefined for index: ${index}`)
                        }


                        await fetch(listURL[index], {
                            method: 'PUT',
                            headers: {
                                'Content-Type': listFileUpload[index].type ,
                            },
                            body: dataBuffer
                        });
                    })
                );

                data = {...data, supplementary_material_urls: listURL}
            }

            //Need to add the combined_attribute or currentYear_categoryUuid to each created article or update as we are using that as the query partition index

            const year = new Date().getFullYear();
            const combined_attribute = `${year}-${category}`

            const payload = newArticle ? {data: {create: [{...data, combined_attribute}]}} : {data: {update: [{...data, uuid, combined_attribute}]}};


            console.log(`${JSON.stringify(payload)}`)
            const formSubmission = await fetch(MANAGE_BEST_PRACTICES, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify(payload)
            });

            //setViewArticle(false);
            //setArticlesLoading(true);
            if(formSubmission.ok){
                setFormSubmitSuccess(true)
                //setArticles(resp.response.success)
                //console.log(`Form submit status: ${JSON.stringify(resp)}`)
            }
            
        } catch(err){
            throw new Error(`Could not submit article update: ${err}`)
        } finally{
            //setViewArticle(false);
        }
    }

    /**
     * Manage editing in the form:
     */
    const toggleEditorMode = (e) => {
        e.preventDefault(); // remove if you want to submit for on toggle of editor mode
        setEditorReadOnly(!editorReadOnly);
    }

    /**
     * Handler to display author info
     */
    const handleEditorDisplay = () => {
        let authorRaw = `${first_name ? first_name: ''} ${last_name ? last_name : ''}`

        let author = authorRaw.split(' ').map(name => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()).join(' ');

        if(new Date(updated_at).getTime() > new Date(created_at).getTime()){
            return `Last updated by: ${author} on ${new Date(updated_at).toLocaleDateString()}`
        } else {
            return `Created by: ${author} on ${new Date(created_at).toLocaleDateString()}`
        }

    }

    /**
     * Sets content state with value in ckEditor
     */
    const editorHandler = (data) => {
        setEditorContent(data); 
    }

    /**
     * Handle tags in articles:
     */
    const handleTagInputChange = (e) => {
        setArticleTag(e.target.value);
    }
 
    const addTag = (tag) => {
        if(!articleTags.includes(tag) && articleTags.length < 3){
            const updatedTags = [...articleTags, tag];
            setArticleTags(updatedTags);
            setValue('tags', updatedTags);
        }
    }

    const handleKeyDown = (e) => {
        if(e.key === 'Enter' && articleTag.trim()){
            e.preventDefault();
            addTag(articleTag.trim());
            setArticleTag('');  
        }
    }

    const removeTag = (tagToRemove) => {
        const updatedTags = articleTags.filter(tag => tag !== tagToRemove);
        setArticleTags(updatedTags);
        setValue('tags', updatedTags);
    }

    /**
     * Handle tool links
     */
    const handleLinkSelect = (e) => {
        setToolChestLinkID(e.target.value);
    }

    /**
     * Handle files supplementary files uploaded by user
     */
    const fileInputRef = useRef(null);

    const handleSelectFile = () => {
        if(fileInputRef.current && fileInputRef.current.files.length > 0){
        const file = fileInputRef.current.files[0];

        setListFileUpload(prevState => {
            const fileExists = prevState.some(f => 
            f.name === file.name && f.type === file.type
            );

            if(!fileExists){
            return [...prevState, {blob: file, name: file.name, type: file.type}];
            }

            return prevState
        });
        }
    }; 

    const removeFile = (file) => {
        const listFiles = [];
    
        listFileUpload.forEach((item) => {
          if (item?.name !== file?.name) {
            listFiles.push(item);
          }
        });
    
        setListFileUpload(listFiles);
      };
    
    
    return(
        <form onSubmit={handleSubmit(onSubmit)} id="bp-form">

        {newArticle ? null :
            <button id="editToggle" onClick={toggleEditorMode}>{editorReadOnly ? 'Toggle Edit Mode' : 'Toggle View Mode'}</button>
        }

        {/*title*/}
        <div className="bp-form-field" id="title-field">
            {editorReadOnly ? (
                <div id="title-author">
                    <h3>{title}</h3>
                    <small>{handleEditorDisplay()}</small>
                </div>

            ) : (
                <>
                <label htmlFor="title">Article Title:</label>
                <input {...register('title', {required: 'A title is required'})} id="title" type="text"/>
                {errors.title && <p className="error-msg">{errors.title.message}</p>}
                </>
            )}
        </div>


        {/*category autopopulated with current category*/}
        {/*<div className="bp-form-field" id="category-field">
            {editorReadOnly ? (
                null
            ) : (
                <>
                    <label htmlFor="category">Category:</label>
                    <input {...register('category')} readOnly id="category"/>
                </>
            )}

        </div>*}
        

        {/*description*/}
        <div className="bp-form-field" id="description-field">
            <label htmlFor="description">Description:</label>
            {editorReadOnly ? (
                (description && description !== '') ? <p>{description}</p> : null
            ) : (
                <> 
                    <input {...register('description', {maxLength: 140})} type="text" id="description"/>
                    {errors.description && <p className='error-msg'>Limit your description to 140 characters.</p>}
                </>
            )}

        </div>


        {/*Context editor*/}
        <div className="bp-form-field" id="content-field">
            <label>Content:</label>
            {editorReadOnly ? (
                (body && body !== '') ? <div dangerouslySetInnerHTML={{__html: body}}/> : <div dangerouslySetInnerHTML={{__html: "<p>No content currenly available.</p>"}}/>
            ) : ( 
                <Editor data={body ? body : ''} onChange={editorHandler}/>
            )}
            
        </div>
        

        {/*File input*/}
        <div className="bp-form-field" id="supplementary-field">
            <label htmlFor="file">Supplementary File(s):</label>
            {editorReadOnly ? (
                supplemental_material_urls && supplemental_material_urls.length > 0 ?
                supplemental_material_urls.map((url, index) => {
                    return(
                        <a key={index} href={url} target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M12 15.248q-.161 0-.298-.053t-.267-.184l-2.62-2.619q-.146-.146-.152-.344t.152-.363q.166-.166.357-.169q.192-.003.357.163L11.5 13.65V5.5q0-.213.143-.357T12 5t.357.143t.143.357v8.15l1.971-1.971q.146-.146.347-.153t.366.159q.16.165.163.354t-.162.353l-2.62 2.62q-.13.13-.267.183q-.136.053-.298.053M6.616 19q-.691 0-1.153-.462T5 17.384v-1.923q0-.213.143-.356t.357-.144t.357.144t.143.356v1.923q0 .231.192.424t.423.192h10.77q.23 0 .423-.192t.192-.424v-1.923q0-.213.143-.356t.357-.144t.357.144t.143.356v1.923q0 .691-.462 1.153T17.384 19z"/></svg>
                            <p>{decodeURIComponent(url?.split('/')?.[url?.split('/').length -1]?.split("?")[0])}</p>
                        </a>
                    )
                }) : null
            ) : (
                <>
                    <input {...register('files')} type="file" id="file" ref={fileInputRef} onChange={handleSelectFile}/>
                    <div className="file-list">
                        {listFileUpload.map((file) => {
                            return(
                                <div key={file.name} className="file-list-item">
                                    {editorReadOnly ? <a href={file.url}>{file.name}</a> : <p>{file.name}</p>}
                                    {editorReadOnly ? null : <span onClick={() => removeFile(file)}>&times;</span>}
                                </div>
                            )
                        })}
                    </div>
                </>
            )}
            

        </div>
        

        {/*Tags*/}
        <div className="bp-form-field" id="tag-field">
            <label htmlFor="tags">Tags {editorReadOnly ? null : (`(limit of 3)`)}:</label>
            {editorReadOnly ? (
                articleTags && articleTags.length > 0 ? (
                    <ul className="tags-list-read">
                        {articleTags.map(tag => {
                            return(<li key={tag}>{tag}</li>)
                        })}
                    </ul>
                ) : null
            ) : (
                <>
                    <input {...register('tags')} type="text" id="tags" value={articleTag} placeholder="Add a tag and press enter" onKeyDown={handleKeyDown} onChange={handleTagInputChange}/>
                    <div className="tag-list">
                        {articleTags.map((tag, index) => (
                            <div key={index}>{tag}
                                <button type="button" onClick={() => removeTag(tag)}>&times;</button>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
        

        {/*Tool Chest*/}
        <div className="bp-form-field" id="tools-field">
            <label htmlFor="tool_link_id">Tool Links:</label>
            {editorReadOnly ? (
                (toolChestLinkID && toolChestLinkID.length > 0) ? (
                    link_meta.map(link => (
                        link.uuid === toolChestLinkID ? (
                            <a key={link.uuid} href={link.url} target="_blank" rel="noreferrer" id="tool-link-url">{link.url}</a>
                        ) : null
                    ))
                ) : null
            ) : (

                (link_meta && link_meta.length > 0) ? (
                    <select {...register('tool_link_id')} id="tool_link_id" className="tool-chest-select" defaultValue={toolChestLinkID}
                    onChange={handleLinkSelect}>
                        <option value=''>Select a link:</option>
                        {link_meta.map(link => (<option key={link.uuid} value={link.uuid}>{link.url}</option>)
                        )}
                    </select>
                ) : null
            )}

        </div>

        {editorReadOnly ? null : 
            <button type="submit" id="submit-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M12 18.16V5.91l5.25 5.25l.75-.66L11.5 4L5 10.5l.75.66L11 5.91v12.25zM3 19h1v2h15v-2h1v3H3z"/></svg>
                <span>Submit</span>
            </button>
        }

        

    </form>
    )
}