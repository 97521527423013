import React, { useState} from "react";
import { DisableButton, GreenButton } from "components/Button";
import Input from "components/Input";
import { Link} from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaSignIn } from "utils/schema";
import "bootstrap/dist/css/bootstrap.css";
import "styles/engage-tool.css";
import logo from '../../../assets/images/logo.webp';


export default function Form({ switchForm, userData, onSubmit }) {
  //const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    // setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaSignIn),
    defaultValue: {
      username: '',
      password: ''
    }
  });

  return (
      <div className="login-form-container">
        <img src={logo} alt="AFSCME Logo" className="logo"/>
        <p className="login-form-header">LOGIN TO YOUR ACCOUNT</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3 login-input-group">
            <Input
              type="text"
              name="username"
              register={register}
              errors={errors}
              placeholder="Email address"
            />
            {errors.username && (
              <small className="login-input-error">
                {errors.username.message}
              </small>
            )}
          </div>
          <div className="mb-3">
            <Input
              type="password"
              name="password"
              register={register}
              errors={errors}
              placeholder="Password"
            />
            {errors.password && (
              <small className="login-input-error">{errors.password.message}</small>
            )}
          </div>
          <div className="login-form-links mb-3">
              <Link className="linkText" to="/forgot_password" style={{textDecoration: 'underline', color: 'white', fontSize: '0.8em'}}>
                FORGOT YOUR PASSWORD
              </Link>
              <Link className="linkText" to="/register" style={{textDecoration: 'underline', color: 'white', fontSize: '0.8em'}}>
                APPLY FOR AN ACCOUNT
              </Link>
          </div>
          {loading ? (
            <DisableButton className="disabled-btn" text="SIGN IN"/>
          ) : (
            <GreenButton className="login-form-btn" text="SIGN IN"/>
          )}

        </form>
      </div>
  );
}
