import React from "react";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import Input from "components/Input";
import { useDispatch } from "react-redux";
import authActions from "redux/actions/auth";
import { Link, useNavigate } from "react-router-dom";
import { GreenButton } from "components/Button";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { notifyError, notifyInfo, notifySuccess } from "utils/helper";
import "bootstrap/dist/css/bootstrap.css";
import { IconCheck, IconClose, IconInfo } from "@aws-amplify/ui-react";
import "styles/engage-tool.css";
import logo from '../../../assets/images/logo.webp'

function Form() {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isInputUser, setIsInputUser] = useState(false);

  const onSubmit = async ({ username, code, password, confirmPassword }) => {
    if (!isInputUser) {
      try {
        await Auth.forgotPassword(username);
        notifyInfo("Check your email inbox.");
        setIsInputUser(true);
      } catch (error) {
        notifyError(
          error?.message
            ? error.message
            : `Data Fetch Error : ${error.status} code`
        );
      }
    } else {
      if (password?.length <= 12) {
        notifyError("Password must be more than 12 characters!");
        return;
      }
      if (confirmPassword === password) {
        try {
          await Auth.forgotPasswordSubmit(username, code, password);
          await Auth.signIn(username, password).then((user) => {
            dispatch(
              authActions.loginSuccess({
                isLoggedIn: true,
                email: user?.attributes?.email,
                name: user?.attributes?.name,
              })
            );
            notifySuccess("Your password is updated successfully!");
            setTimeout(() => {
              navigate("/login");
            }, 2000);
          });
        } catch (error) {
          notifyError(
            error?.message ? error.message : `Data Fetch Error ${error.status}`
          );
        }
      } else {
        notifyError(
          "Password and Confirm Password do not match. Please try again!"
        );
      }
    }
  };

  const checkPassword = (event) => {
    setPassword(event.target.value);
  };

  return (
    <div className="login-form-container">
      <ToastContainer />
      <img src={logo} alt="AFSCME logo" className="logo"/>
      <p className="login-form-header">REQUEST CODE TO RESET PASSWORD</p>
      {/* forgot password form */}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <input
              autoComplete="off"
              name="hidden"
              type="text"
              className="hidden"
            />
            <div>
              {!isInputUser ? (
                <div>
                  <Input
                    type="text"
                    name="username"
                    register={register}
                    errors={errors}
                    placeholder="Email address"
                  />
                </div>
              ) : (
                <div className="flex flex-col justify-start items-end">
                  <div className="w-full">
                    <Input
                      type="text"
                      name="code"
                      autoComplete="new-code"
                      register={register}
                      errors={errors}
                      placeholder="Code"
                    />
                    <div className="relative">
                      <Input
                        type="password"
                        name="password"
                        register={register}
                        errors={errors}
                        placeholder="Password"
                        onChange={checkPassword}
                      />
                      {/* tooltip */}
                      <div className="absolute right-2 text-gray-500 top-2 group">
                        <IconInfo />
                        <div className="hidden group-hover:block absolute w-80 p-4 shadow-lg bg-slate-50 text-black-10 rounded-md">
                          <div className="flex">
                            {password?.length > 12 ? (
                              <div className="text-green-500">
                                <IconCheck />
                              </div>
                            ) : (
                              <div className="text-red-500">
                                <IconClose />
                              </div>
                            )}
                            Over 12 characters
                          </div>
                        </div>
                      </div>
                    </div>
                    <Input
                      type="password"
                      name="confirmPassword"
                      register={register}
                      errors={errors}
                      placeholder="Confirm Password"
                    />
                  </div>
                </div>
              )}
              <Link className="linkText forgot-pswd-sign-in" to="/login">
                SIGN IN
              </Link>
              <GreenButton
                text={`${!isInputUser ? "SEND CODE" : "CHANGE PASSWORD"}`}
                className="login-form-btn"
              />
            </div>
          </form>
    </div> 
  );
}

export default Form;
