import React, { useState } from "react";
import { DisableButton, GreenButton } from "components/Button";
import Input from "components/Input";
import { useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { schemaNewPassword } from "utils/schema";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import authActions from "redux/actions/auth";
import { notifyError, notifySuccess } from "utils/helper";
import { IconCheck, IconClose, IconInfo } from "@aws-amplify/ui-react";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Form() {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const dispatch = useDispatch();

  //const [strength, setStrength] = useState("");
  const [password, setPassword] = useState("");

  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaNewPassword),
  });

  const onSubmit = async ({ newPassword, newPasswordVerify }) => {
    if (newPassword === newPasswordVerify) {
      if (newPassword.length > 12) {
        try {
          setLoading(true);
          Auth.signIn(query.get("u").toLowerCase(), query.get("v"))
            .then((user) => {
              if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                Auth.completeNewPassword(user, newPassword)
                  .then((user) => {
                    dispatch(
                      authActions.loginSuccess({
                        isLoggedIn: true,
                        email: user?.challengeParam?.userAttributes?.email,
                        name: user?.challengeParam?.userAttributes?.name,
                      })
                    );
                    notifySuccess("Your password is updated successfully");
                    setLoading(false);
                    setTimeout(() => {
                      navigate("/login");
                    }, 2000);
                  })
                  .catch((err) => {
                    notifyError(err.message);
                    setLoading(false);
                  });
              }
            })
            .catch((err) => {
              setLoading(false);
              notifyError(
                err?.message ? err.message : `Data Fetch Error ${err.status}`
              );
            });
        } catch (err) {
          notifyError(
            err?.message ? err.message : `Data Fetch Error ${err.status}`
          );
        }
      } else {
        notifyError("Password must be more than 12 characters.");
      }
    } else {
      notifyError(
        "Password and Confirm Password do not match. Please try again."
      );
    }
  };

  const checkPassword = (event) => {
    setPassword(event.target.value);
  };

  return (
    <div className="flex justify-center">
      <ToastContainer />
      <div className="flex justify-center my-auto top-0 bottom-0 px-3 mx-auto  w-full">
        <div className=" px-5 py-5 drop-shadow-2xl rounded-xl  min-w-[290px] ">
          <div className="form-title mt-10 mb-10">REGISTRATION PASSWORD</div>

          {/* update password */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-4">
              <div className="pb-4 relative">
                <Input
                  onChange={checkPassword}
                  type="password"
                  name="newPassword"
                  register={register}
                  errors={errors}
                  placeholder="New password"
                />
                {/* tooltip */}
                <div className="absolute right-2 text-gray-500 top-2 group">
                  <IconInfo />
                  <div className="hidden group-hover:block absolute w-80 p-4 shadow-lg bg-slate-50 text-black-10 rounded-md">
                    <div className="flex">
                      {password?.length > 12 ? (
                        <div className="text-green-500">
                          <IconCheck />
                        </div>
                      ) : (
                        <div className="text-red-500">
                          <IconClose />
                        </div>
                      )}
                      Over 12 characters
                    </div>
                  </div>
                </div>
              </div>

              <div className="pb-2">
                <Input
                  type="password"
                  name="newPasswordVerify"
                  register={register}
                  errors={errors}
                  placeholder="Confirm Password"
                />
              </div>

              <div className="flex items-center justify-center text-lg font-bold mt-10">
                {loading ? (
                  <DisableButton text="Please wait" />
                ) : (
                  <GreenButton text="UPDATE PASSWORD" />
                )}
              </div>
            </div>
          </form>
        </div>

        {/* <div className="hidden sm:block w-full max-w-[560px] drop-shadow-2xl">
          <div className="flex items-center justify-center h-full rounded-r-sm bg-slate-800-70">
            {/* <p className="px-6 text-center text-white md:px-10 lg:px-12 sm:text-2xl md:text-3xl">
              For people proudly committed to the public service that makes
              America happen, we never quit.
            </p> */}
        {/*
          </div>
        </div> */}
      </div>
    </div>
  );
}
