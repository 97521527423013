import axios from "./setupAxios";
import { CALENDAR_EVENT, MANAGE_CALENDAR } from "utils/request";

export const event = axios.create({
  baseURL:
    "https://68eimkhva8.execute-api.us-east-1.amazonaws.com/dev/v1/manage-calendar-requests",
});

export const requestAddCalendarEvent = ({ data }) => {
  return axios.post(MANAGE_CALENDAR, data);
};

export const requestGetCalendarEvent = ({ params }) => {
  return axios.get(CALENDAR_EVENT, { params });
};

export const filterCalendarEvent = ({ params }) => {
  return axios.get(CALENDAR_EVENT, { params });
};
