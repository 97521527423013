import { loginActionTypes, logoutActionType } from "utils/constants";

const initialState = {
  isLoggedIn: false,
  email: null,
  role: null,
  aff_pk: null,
  state: null,
  affiliate_assoc: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        email: action.payload.email,
        name: action.payload.name,
        isLoggedIn: true,
        role: action.payload.role,
        aff_pk: action.payload.aff_pk,
        state: action.payload?.state,
        affiliate_assoc: action.payload?.affiliate_assoc,
      };
    case logoutActionType.LOGOUT:
      return {
        ...state,
        email: null,
        isLoggedIn: false,
        role: action.payload.role,
      };
    case loginActionTypes.LOGIN_FAILED:
      return {
        ...state,
        email: null,
        isLoggedIn: false,
      };

    default:
      return state;
  }
};
