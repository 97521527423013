import Layout from "components/layout";
import React from "react";
import { TITLE_REGISTRATION_FORM } from "utils/constants";

export default function User() {
  return (
    
    <Layout title={TITLE_REGISTRATION_FORM}>
      <div className="mt-16" style={{fontSize:24, textAlign: 'center', width:'100%', fonstWeight: 'bold'}}>Welcome to Engage-Tool Page</div>
    </Layout>
  );
}
