import { previousPageTypes } from "utils/constants";

const addPreviousURL = (payload) => ({
  type: previousPageTypes.ADD_URL_PAGE,
  payload,
});

const deletePreviousURL = (payload) => ({
  type: previousPageTypes.DELETE_URL_PAGE,
  payload,
});

const previousPageAction = {
  addPreviousURL,
  deletePreviousURL,
};

export default previousPageAction;
