import { BackIcon } from "assets/icons/Icons";
import Layout from "components/layout";
import Loading from "components/Loading";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { requestAddCategory } from "services/bestpractice";
import { fetchAffliatessAPI, fetchStateAPI } from "services/register";
import { TITLE_BP } from "utils/constants";
import { notifyError, notifySuccess } from "utils/helper";
import Select from "react-select";
import { Auth } from "aws-amplify";

function AddCategory() {
  const { register, handleSubmit, setValue, control } = useForm();
  const authInfo = useSelector((state) => state.auth);
  const [IUCategory, setIUCategory] = useState("YES");

  const [dataState, setDataState] = useState([]);
  const [selectState, setSelectState] = useState("");

  const [dataAffliates, setDataAffliates] = useState([]);
  const [selectAffliates, setSelectAffliates] = useState("");

  const [loading, setLoading] = useState(false);

  const getAff_pk = () => {
    const role = authInfo?.role;
    if (role === "affiliate_admin" || role === "user") return +authInfo?.aff_pk;

    if (role === "system_admin" && IUCategory === "YES") return 14429;

    if (role === "system_admin" && IUCategory === "NO")
      return +selectAffliates?.aff_pk;
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const aff_pk = getAff_pk();

    try {
      await requestAddCategory({
        data: {
          create: [
            {
              title: data?.title,
              description: data?.description,
              aff_pk: aff_pk,
              state: data?.state,
            },
          ],
        },
      });

      setValue("title", "");
      setValue("description", "");
      notifySuccess("successfully");
      window.location.href = "/best-practice/";
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
    }

    setLoading(false);
  };

  const getStates = async () => {
    try {
      const response = await fetchStateAPI();

      if (!response?.data) return;
      const selectStateData = response?.data.map((item) => {
        return { value: item?.state, label: item.state };
      });

      setDataState(
        selectStateData.sort((a, b) => {
          if (a.value > b.value) {
            return 1;
          }
          if (b.value > a.value) {
            return -1;
          }
          return 0;
        })
      );
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error", error);
    }
  };

  const getAffliates = async () => {
    try {
      const response = await fetchAffliatessAPI({
        data: { state: selectState.value },
      });

      if (response && response.data) {
        if (response.data.length === 0) {
          setDataAffliates([]);
        } else {
          var filteredAffliatedData = response?.data;
          filteredAffliatedData = filteredAffliatedData.filter((x) => {
            if (x.type === "C" || x.type === "R" || x.type === "L") {
              return x;
            }
          });
          const selectAffliatesData = filteredAffliatedData
            .sort((a, b) => {
              if (a.short_name > b.short_name) {
                return 1;
              }
              if (b.short_name > a.short_name) {
                return -1;
              }
              return 0;
            })
            .map((item) => {
              return {
                ...item,
                value: item?.short_name,
                label: item?.short_name,
                affPk: item?.aff_pk,
                parent: item?.parent,
              };
            });

          setDataAffliates([
            //{ value: "all", label: "All", state: selectAffliatesData[0].state },
            ...selectAffliatesData,
          ]);
        }
      }
    } catch (error) {
      notifyError(
        error?.message ? error.message : `Data Fetch Error ${error.status}`
      );
      console.log("error:", error);
    }
  };

  const handleSelectState = (event) => {
    setSelectState(event);
    setSelectAffliates("");
  };

  const handleSelectAffliates = (event) => {
    setSelectAffliates(event);
  };

  const toggleIUCategory = (value) => {
    setIUCategory(value);
  };

  const handleCancel = (event) => {
    event.preventDefault();
    window.location.href = "/best-practice";
  };

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    if (selectState) {
      getAffliates();
    }
  }, [selectState]);

  return (
    <Layout title={TITLE_BP}>
      <Loading isVisible={loading} />
      <ToastContainer />

      <div id="nav2">
        <Link to="/dashboard" className="ms-4 mx-1">Calendar</Link>/
        <Link to="/tool-chest" className="mx-1">Tool Chest</Link>/
        <Link to="/best-practice" className="active ms-1">
          Best Practices
        </Link>
      </div>

      <div className="container pb-10">
        <div className="flex items-center">
          <Link to="/best-practice" className="cursor-pointer text-white">
            <BackIcon size={48} />
          </Link>
          <h3 className="flex-1 text-center my-8">New Category</h3>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="container mx-auto bg-blue-20 space-y-6 rounded-md"
        >
          {/* Input IU category */}
          {authInfo.role === "system_admin" ? (
            <>
              <div className="mb-4">
                <div className="flex items-center">
                  <label htmlFor="title" className="form-label font-bold">
                    IU Category
                  </label>
                  <div className="flex justify-start items-center gap-4 ml-4">
                    <div className="flex items-center">
                      <input
                        {...register("IUCategory")}
                        id="IUCategoryT"
                        className="accent-green-10 w-6 h-6 cursor-pointer"
                        type="radio"
                        value="YES"
                        defaultChecked
                        onChange={() => toggleIUCategory("YES")}
                      />
                      <label
                        className="cursor-pointer pl-1"
                        htmlFor="IUCategoryT"
                      >
                        YES
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        {...register("IUCategory")}
                        id="IUCategoryF"
                        className="accent-green-10 w-6 h-6 cursor-pointer"
                        type="radio"
                        value="NO"
                        onChange={() => toggleIUCategory("NO")}
                      />
                      <label className="cursor-pointer pl-1" htmlFor="IUEventF">
                        NO
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* input aff_pk */}
              <div className="mb-4">
                <Controller
                  name="state"
                  render={({ field }) => (
                    <Select
                      name="selectState"
                      className="selectBox"
                      {...field}
                      options={dataState}
                      onChange={handleSelectState}
                      value={selectState}
                      placeholder="State"
                      style={{ fontSize: 20, padding: 20 }}
                      tabIndex={7}
                      isDisabled={IUCategory === "YES"}
                    />
                  )}
                  control={control}
                />
              </div>
              <div>
                <Controller
                  name="affliates"
                  render={({ field }) => (
                    <Select
                      className="selectBox"
                      {...field}
                      options={dataAffliates}
                      isDisabled={
                        selectState.length === 0 || IUCategory === "YES"
                      }
                      onChange={handleSelectAffliates}
                      value={selectAffliates}
                      placeholder="Affiliate"
                      style={{ fontSize: 20, padding: 20 }}
                      tabIndex={8}
                    />
                  )}
                  control={control}
                />
              </div>{" "}
            </>
          ) : (
            <>
              {" "}
              <div className="mb-4">
                <Controller
                  name="state"
                  render={({ field }) => (
                    <Select
                      name="selectState"
                      className="selectBox"
                      {...field}
                      //options={dataState}
                      //onChange={handleSelectState}
                      //value={selectState}
                      value={Auth.user.signInUserSession.idToken.payload.state}
                      //placeholder="State"
                      placeholder={
                        Auth.user.signInUserSession.idToken.payload.state
                      }
                      style={{ fontSize: 20, padding: 20 }}
                      tabIndex={7}
                      isDisabled={authInfo.role === "affiliate_admin"}
                    />
                  )}
                  control={control}
                />
              </div>
              <div>
                <Controller
                  name="affliates"
                  render={({ field }) => (
                    <Select
                      className="selectBox"
                      {...field}
                      //options={dataAffliates}
                      isDisabled={authInfo.role === "affiliate_admin"}
                      //onChange={handleSelectAffliates}
                      value={
                        Auth.user.signInUserSession.idToken.payload
                          .affiliate_assoc
                      }
                      placeholder={
                        Auth.user.signInUserSession.idToken.payload
                          .affiliate_assoc
                      }
                      style={{ fontSize: 20, padding: 20 }}
                      tabIndex={8}
                    />
                  )}
                  control={control}
                />
              </div>
            </>
          )}
          {/* Input title */}
          <input
            type="text"
            placeholder="Title"
            className="w-full text-base font-bold text-black-10 p-2 outline-none rounded-md px-3 border-b"
            {...register("title", { required: true })}
          />
          {/* Input description */}
          <textarea
            placeholder="Description"
            className="w-full text-base font-bold text-black-10 p-2 outline-none rounded-md px-3 border-b min-h-[200px]"
            {...register("description", { required: true })}
          />

          <div className="flex justify-end gap-4">
            <button
              onClick={handleCancel}
              className="bg-red-10 py-2 px-3 text-white font-bold rounded-full text-sm min-w-[90px] hover:opacity-80 shadow-lg duration-500 truncate"
            >
              Cancel
            </button>
            <button className="bg-green-10 py-2 px-3 text-white font-bold rounded-full text-sm min-w-[90px] hover:opacity-80 shadow-lg duration-500 truncate">
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
}

export default AddCategory;
